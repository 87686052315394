import React, { Fragment } from "react";

import "./responsive.css";
import "./Home.css";

import Header from "../components/Common/Navbar";
import Panache from "../components/Home/Panache";
import Inox from "../components/Home/Inox";
import Grandeur from "../components/Home/Grandeur";
import Categories from "../components/Home/Categories";
import Offers from "../components/Home/Offers";
import Events from "../components/Home/Events";
import Memoirs from "../components/Home/Memoirs";
import StayConnect from "../components/Home/StayConnect";
import Help from "../components/Home/Help";
import Nhance from "../components/Home/Nhance";
import InoxMob from "../components/Home/InoxMob";
import Sidebar from "../components/Common/NavbarMob";
import BannerVideo from "../components/Common/BannerVideo";

import BannerV from "../assets/Home/MOA Logo Reveal - website.mp4";

const Home = (props) => {
  return (
    <Fragment>
      <Header />
      <Sidebar />
      <BannerVideo
      />
      <Panache />
      <Grandeur />
      <Categories />
      <Inox />
      {/*<InoxMob /> */}
      <Offers />
      {/* <Nhance /> */}
      <Events />
      <Memoirs />
      <StayConnect />
      <Help />
    </Fragment>
  );
};

export default Home;
