import React, { Fragment, useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./PressRelease.css";
import Card13 from "../Common/Card/Card13";
import axios from 'axios';
import { useParams } from 'react-router-dom';

const PressRelease = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    axios.get("https://testadmin1.phoenixmallofasia.com/events/api/newstable")
      .then(response => {
        // Filter the data to include only news with status "Press"
        const pressNews = response.data.filter(news => news.newsstatus === "Press");
        setNews(pressNews);
      })
      .catch(error => {
        console.error('Error fetching news:', error);
      });
  }, []);


  return (
    <div className="press_release pb-md-5 pt-md-0 pb-0">
      <div className="main-pad pe-md-0">
        <div className="d-flex pt-md-4">
          {/* <h2>PRESS RELEASE</h2> */}
          <h2> {news.length > 0 ? news[0].toptitle : ""} </h2>
          <hr />
        </div>
      </div>
      <div className="main-pad">
        <div className="container-fluid">
          <OwlCarousel
            items={2}
            className="owl-theme mt-md-5 pt-md-0 my-3"
            loop
            nav
            autoplay
            margin={25}
            responsive={{
              0: { items: 1 },
              450: { items: 1 },
              600: { items: 3 },
              1000: { items: 3 },
            }}
          >
            {/* Map through the events array and render Card13 components */}
            {news.map(news => (
              <div key={news.id}>
                <a href={`/news/${news.title}`}>
                  <Card13
                    image={`https://testadmin1.phoenixmallofasia.com${news.photo1_path}`} // Use the image URL from the fetched data
                    title={news.title}
                    para={news.description}
                    author={news.author}
                    date={news.date}
                    time={news.timing}
                  />
                </a>
              </div>
            ))}

          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default PressRelease;
