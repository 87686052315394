import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LuxEvents.css";
import Container from "react-bootstrap/esm/Container";

export default function LuxEvents() {
  const [events, setEvents] = useState([]);
  const [activeContentIndex, setActiveContentIndex] = useState(0);

  useEffect(() => {
    // Fetch data from API when component mounts
    axios.get("https://testadmin1.phoenixmallofasia.com/events/api/eventluxury")
      .then(response => {
        setEvents(response.data);
      })
      .catch(error => {
        console.error("Error fetching events:", error);
      });
  }, []);

  return (
    <div className="lux_events py-md-0 pt-4">
      <Container className="my-md-4">
        <div id="tabs">
          <menu>
            {events.map((event, index) => (
              <button
                key={index}
                className={activeContentIndex === index ? "active" : ""}
                onClick={() => setActiveContentIndex(index)}
              >
                <div className="dot"></div> {event.title}
              </button>
            ))}
          </menu>
          <div id="tab-content">
            <ul>
              {events.length > 0 && (
                <li>
                  <div className="row mt-md-5 pt-md-5 pt-xl-0">
                    <div className="col-md-6">
                      <img src={`https://testadmin1.phoenixmallofasia.com/${events[activeContentIndex].photo2_path}`} alt={events[activeContentIndex].title} />
                    </div>
                    <div className="col-md-6 pt-md-5 ">
                      <header>
                        <h2> Events</h2>
                      </header>
                      <div className="text mt-md-5">
                        <h3>{events[activeContentIndex].title}</h3>
                        <p>{events[activeContentIndex].description}</p>
                        <p>
                          <span>Date: </span>{events[activeContentIndex].date}
                        </p>
                        <p>
                          <span>Location: </span>{events[activeContentIndex].venue}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
}
