import React, { Fragment, useState, useEffect } from "react";
import "./Dine.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import Foodthopia from "../assets/Dine/Food THopia 1.jpg";
import Help from "../components/Home/Help";
import More from "../components/Common/More";
import Form1 from "../components/Common/Form1";
import DineCategory from "../components/Dine/DineCategory";
import DineView from "../components/Dine/DineViewMore";
import axios from "axios";

const Dine = (props) => {
  const [threeData, setThreeData] = useState(null);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/dinesectionthree")
      .then((response) => response.json())
      .then((data) => setThreeData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  const [oneData, setOneData] = useState(null);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/dinesectionone")
      .then((response) => response.json())
      .then((data) => setOneData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  const [moreData, setMoreData] = useState(null);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/dinesectionfive")
      .then((response) => response.json())
      .then((data) => setMoreData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <Fragment>
      <div className="dine">
        <Header />
        <Sidebar />
        {oneData && oneData.length > 0 && (
          <Banner
            title={oneData[0].title}
            para={oneData[0].description}
            image={`https://testadmin1.phoenixmallofasia.com${oneData[0].photo1_path}`}
          />
        )}
        <div>
          <div className="py-md-5 pt-4">
            <DineCategory />
          </div>
        </div>
        {threeData && threeData.length > 0 && (

          <div className="foodthopia mb-md-4 mb-xl-0">
            <img src={`https://testadmin1.phoenixmallofasia.com${threeData[0].photo1_path}`} />
            <h2>{threeData[0].title}</h2>
          </div>
        )}
        {moreData && moreData.length > 0 && (
          <>
            <div className="more pt-xl-5 pt-4">
              <Fragment>
                <div className="d-flex mt-xl-4 pb-md-5 main-pad pe-0">
                  <h2>{moreData[0].title1} </h2>
                  <hr />
                </div>
                <More
                  title1={moreData[0].title2}
                  title2={moreData[0].title3}
                  image1={`https://testadmin1.phoenixmallofasia.com${moreData[0].photo1_path}`}
                  image2={`https://testadmin1.phoenixmallofasia.com${moreData[0].photo2_path}`}
                  url={moreData[0].content1link}
                />
              </Fragment>
            </div>
          </>

        )}
        <Help />
        <Form1
          tag="Dines"
          title="Are you having trouble deciding where to eat? "
          para="Ask us and we'll be happy to help you choose!"
        />
      </div>
    </Fragment>
  );
};

export default Dine;
