import React, { Fragment, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./NewsHead.css";
import Card13 from "../Common/Card/Card13";
import { useParams } from 'react-router-dom';
import axios from 'axios';

const NewMore = (props) => {
  const { id } = useParams();
  const [news, setNews] = useState([]);

  useEffect(() => {
    async function fetchNews() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/news/${id}`);
        console.log(response + "newsresponse");
        setNews(response.data.newsallValue.split(',').map(newsTitle => ({ title: newsTitle.trim() })));
      } catch (error) {
        console.error('Error fetching news: ' + error.message);
        setNews([]);
      }
    }

    fetchNews();
  }, [id]);

  useEffect(() => {
    async function fetchNewData(newsTitle) {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/news/${newsTitle}`);
        // Assuming the response data structure is similar to your initial API response
        // Modify this part according to your actual API response structure
        console.log(response);
        setNews(prevNews => prevNews.map(news => {
          if (news.title === newsTitle) {
            return response.data;
          }
          return news;
        }));
      } catch (error) {
        console.error(`Error fetching data for news "${newsTitle}": ` + error.message);
      }
    }

    news.forEach(news => {
      if (news.title) {
        fetchNewData(news.title);
      }
    });
  }, [news]);

  return (
    <Fragment>
      <div className="blog_more pb-md-5 pt-md-0 pt-4">
        <div className="main-pad pe-md-0 pe-0">
          <div className="d-flex pt-md-4">
            <h2> MORE FROM MALL OF ASIA NEWS </h2>
            <hr />
          </div>
        </div>
        <Container>

          <div className="row my-md-5 my-4">
            {news.slice(0, 2).map((news, index) => (
              <div className="col-md-6" key={index}>
                <a href={`/news/${news.title}`}>
                  <Card13
                    image={`https://testadmin1.phoenixmallofasia.com/${news.photo2_path}`}
                    title={news.title}
                    para={news.description}
                    author={news.author}
                    date={news.date}
                    time={news.timing}
                  />
                </a>
              </div>
            ))}
          </div>

        </Container>
      </div>
    </Fragment>
  );
};

export default NewMore;
