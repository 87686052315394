import React, { Fragment, useState, useEffect } from "react";
import "./LuxPageCategory.css";

import Card3 from "../Common/Card/Card3";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi'; // Import the search icon from react-icons/bi



const LuxPageCategory = ({ props }) => {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const [brand, setBrand] = useState(null);
    const [uniqueCategories, setUniqueCategories] = useState([]);
    const [titleFilter, setTitleFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('');
    const [visibleItemCount, setVisibleItemCount] = useState(8);
    const itemsToAdd = 4;

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch('https://testadmin1.phoenixmallofasia.com/api/brandluxury');
                const jsonData = await response.json();
                setData(jsonData);

                // Extract unique categories from the data
                const uniqueCategories = [...new Set(jsonData.map((brand) => brand.category))];
                setUniqueCategories(uniqueCategories);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchUserData() {
            try {
                const response = await axios.get(`/luxury/${id}`);
                setBrand(response.data);
            } catch (error) {
                console.error('Error fetching user data: ' + error.message);
                setBrand(null);
            }
        }
        fetchUserData();
    }, [id]);

    const handleTitleFilter = (filterValue) => {
        setTitleFilter(filterValue);
    };

    const handleCategoryFilter = (filterValue) => {
        setCategoryFilter(filterValue);
    };

    const handleFilterSelection = (filter) => {
        setSelectedFilter(filter);
    };

    const filteredBrands = data.filter((brand) => {
        const categoryMatch = categoryFilter === '' || brand.category === categoryFilter;
        const titleMatch = titleFilter === '' || brand.title.toLowerCase().includes(titleFilter.toLowerCase());
        const filterMatch = selectedFilter === '' || checkFilterRange(brand.title[0], selectedFilter);

        return categoryMatch && titleMatch && filterMatch;
    });

    // Create a helper function to check if a character falls within a filter range
    function checkFilterRange(character, filter) {
        switch (filter) {
            case 'A-E':
                return 'A' <= character && character <= 'E';
            case 'F-J':
                return 'F' <= character && character <= 'J';
            case 'K-O':
                return 'K' <= character && character <= 'O';
            case 'P-T':
                return 'P' <= character && character <= 'T';
            case 'U-Z':
                return 'U' <= character && character <= 'Z';
            default:
                return true; // No filter selected, so show all
        }
    }

    return (
        <div className="brand_view main-pad ">
            <div className="row px-md-0 px-2 brands_category">
                <div className="first-two">
                    <div className="middle-container category-filter ">
                        <select className="custom-select"
                            value={categoryFilter}
                            onChange={(e) => handleCategoryFilter(e.target.value)}
                        >
                            <option value="">All Categories</option>
                            {uniqueCategories.map((category, index) => (
                                <option key={index} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>

                        <div className="input-wrapper">
                            <input
                                type="text"
                                placeholder="Search"
                                value={titleFilter}
                                onChange={(e) => handleTitleFilter(e.target.value)}
                            />
                            <BiSearch className="search-icon" />
                        </div>
                    </div>
                    <div className="middle-container">
                        <div className="button-group-select button-group-select">
                            <button onClick={() => handleFilterSelection('A-E')}>A-E</button>
                            <button onClick={() => handleFilterSelection('F-J')}>F-J</button>
                            <button onClick={() => handleFilterSelection('K-O')}>K-O</button>
                            <button onClick={() => handleFilterSelection('P-T')}>P-T</button>
                            <button onClick={() => handleFilterSelection('U-Z')}>U-Z</button>
                        </div>
                    </div>
                </div>
                {filteredBrands.length === 0 ? (
                    <p>No matches found.</p>
                ) : (
                    <div className="row px-md-0 px-2 ms-0">
                        {filteredBrands.slice(0, visibleItemCount).map((brand) => (
                            <div className="col-md-3 col-6 px-md-3 px-1" key={brand.id}>
                                <a href={`/luxury/${brand.title}`}>
                                    <Card3
                                        category={brand.category}
                                        image={`https://testadmin1.phoenixmallofasia.com${brand.photo1_path}`}
                                        location={brand.contact}
                                        floor={brand.floor}
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                )}


                {filteredBrands.length > visibleItemCount && (
                    <button onClick={() => setVisibleItemCount(prevCount => prevCount + itemsToAdd)}>
                        View More
                    </button>
                )}
            </div>
        </div>
    );
};

export default LuxPageCategory;
