import React, { Fragment, useState, useEffect } from "react";
import "./Mall.css";
import Container from "react-bootstrap/esm/Container";
import MallModal from "./MallModal";

export default function Mall() {
  const [activeContentIndex, setActiveContentIndex] = useState(0);
  const [floorData, setOneData] = useState(null);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/brandsectionthree")
      .then((response) => response.json())
      .then((data) => setOneData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <div className="mall py-xl-0 pt-4">
      {floorData && floorData.length > 0 && (
        <>
          <header className="main-pad pe-md-0">
            <div className="d-flex">
              <h2> {floorData[0].floortitle} </h2>
              <hr />
            </div>
          </header>

          <Container className="mt-xl-4 px-0">
            <div id="tabs">
              <menu>
                <button
                  className={activeContentIndex === 0 ? "active" : ""}
                  onClick={() => setActiveContentIndex(0)}
                >
                  <div className="dot"></div> {floorData[0].floortitle1}
                </button>
                <button
                  className={activeContentIndex === 1 ? "active" : ""}
                  onClick={() => setActiveContentIndex(1)}
                >
                  <div className="dot"></div> {floorData[0].floortitle2}
                </button>
                <button
                  className={activeContentIndex === 2 ? "active" : ""}
                  onClick={() => setActiveContentIndex(2)}
                >
                  <div className="dot"></div> {floorData[0].floortitle3}
                </button>
                <button
                  className={activeContentIndex === 3 ? "active" : ""}
                  onClick={() => setActiveContentIndex(3)}
                >
                  <div className="dot"></div> {floorData[0].floortitle4}
                </button>
                <button
                  className={activeContentIndex === 4 ? "active" : ""}
                  onClick={() => setActiveContentIndex(4)}
                >
                  <div className="dot"></div> {floorData[0].floortitle5}
                </button>
              </menu>
              <div id="tab-content">
                <ul>
                  {activeContentIndex === 0 && (
                    <li key={0}>
                      <MallModal img={`https://testadmin1.phoenixmallofasia.com${floorData[0].floorphoto1_path}`} fimg={`https://testadmin1.phoenixmallofasia.com${floorData[0].floorphoto1full_path}`} />
                    </li>
                  )}
                  {activeContentIndex === 1 && (
                    <li key={1}>
                      <MallModal img={`https://testadmin1.phoenixmallofasia.com${floorData[0].floorphoto2_path}`} fimg={`https://testadmin1.phoenixmallofasia.com${floorData[0].floorphoto2full_path}`} />
                    </li>
                  )}
                  {activeContentIndex === 2 && (
                    <li key={2}>
                      <MallModal img={`https://testadmin1.phoenixmallofasia.com${floorData[0].floorphoto3_path}`} fimg={`https://testadmin1.phoenixmallofasia.com${floorData[0].floorphoto3full_path}`} />
                    </li>
                  )}
                  {activeContentIndex === 3 && (
                    <li key={3}>
                      <MallModal img={`https://testadmin1.phoenixmallofasia.com${floorData[0].floorphoto4_path}`} fimg={`https://testadmin1.phoenixmallofasia.com${floorData[0].floorphoto4full_path}`} />
                    </li>
                  )}
                  {activeContentIndex === 4 && (
                    <li key={4}>
                      <MallModal img={`https://testadmin1.phoenixmallofasia.com${floorData[0].floorphoto5_path}`} fimg={`https://testadmin1.phoenixmallofasia.com${floorData[0].floorphoto5full_path}`} />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </Container>
        </>)
      }
    </div>

  );
}
