import React, { Fragment, useEffect, useState } from "react";
import "./Services.css";
import Header from "../components/Common/Navbar";
import Help from "../components/Home/Help";
import Sidebar from "../components/Common/NavbarMob";
import Form1 from "../components/Common/Form1";
import Banner from "../components/Common/Banner";
import ServiceTab from "../components/Services/ServiceTab";

const Services = (props) => {
  const [serviceoneData, setTwoData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/servicesectionone")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <div className="services">
      <Fragment>
        <Header />
        <Sidebar />
        {serviceoneData && serviceoneData.length > 0 && (
          <>
            <Banner image={`https://testadmin1.phoenixmallofasia.com${serviceoneData[0].photo1_path}`} />

          </>
        )}
        <ServiceTab />
        <Help />
        <Form1 tag="Services" title="Luxury is a part of our identity." para="If you have any questions, ask us here!" />
      </Fragment>
    </div>
  );
};

export default Services;
