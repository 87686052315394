import React, { Fragment, useEffect, useState } from "react";
import "./News.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import MediaNews from "../components/News/MediaNews";
import PressRelease from "../components/News/PressRelease";

const News = (props) => {
  const [newsoneData, setOneData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/events/api/newssectionone")
      .then((response) => response.json())
      .then((data) => setOneData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <Fragment>
      <div className="news">
        <Header />
        <Sidebar />
        {newsoneData && newsoneData.length > 0 && (
          <>
            <Banner image={`https://testadmin1.phoenixmallofasia.com${newsoneData[0].photo1_path}`} />

          </>
        )}
        {/* <Banner image="img/News/banner.png" /> */}
        <MediaNews
        // title="MEDIA AND NEWS"
        // title1="Did you come here for something in particular or just general Riker-bashing?"
        // para="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi "
        // image="img/News/top.jpg"
        // date="02 december 2022"
        // time="3 min. to read"
        />
        <PressRelease />
      </div>
    </Fragment>
  );
};

export default News;
