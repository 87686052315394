import React, { Fragment, useState, useEffect } from "react";
import "./Blogs.css";
import Banner from "../components/Common/Banner";
import LaunchBanner from "../assets/Home/panache1.jpg";
import Form1 from "../components/Common/Form1";
import NewsHead from "../components/News/NewsHead";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import NewsMore from "../components/News/NewsMore";
import { useParams } from 'react-router-dom'; // Import useParams
import axios from 'axios';

const NewsDetails = (props) => {
  const { id } = useParams(); // Use useParams to access the 'id' parameter
  const [news, setNews] = useState(null);


  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/news/${id}`);
        setNews(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setNews(null);
      }
    }

    fetchUserData();
  }, [id]);
  return (
    <Fragment>
      <div className="blog_details">
        <Header />
        <Sidebar />
        {news && (
          <>
            <Banner title={news.title} image={`https://testadmin1.phoenixmallofasia.com/${news.photo1_path}`} />
          </>

        )}
        <NewsHead />
        <NewsMore />
        <Form1 tag="NewsDetails"
          title="Have a question? Connect with us!"
          para="Write to us and we will reach out shortly."
        />
      </div>
    </Fragment>
  );
};

export default NewsDetails;
