import React, { Fragment, useState, useEffect } from "react";
import "./Banner.css";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";

const BannerCarouselnew = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://testadmin1.phoenixmallofasia.com/pages/api/newarrival");
      // Filter banners where sections is equal to "sectionone"
      const filteredBanners = response.data.filter(banner => banner.sections === "sectionone");
      setBanners(filteredBanners);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="banner">
      <Fragment>
        <Carousel autoplay>
          {banners.map((banner, index) => (
            <Carousel.Item key={index}>
              {/* Append the image path to the base URL */}
              <img src={`https://testadmin1.phoenixmallofasia.com${banner.gallery1_path}`} alt={banner.title} />
              <div className="overlay"></div>
              <div className="banner-text">
                <a href={banner.ctalink}>
                  <h1>{banner.title}</h1>
                  <p>{banner.desctext}</p>
                  {/* <p>{banner.ctatext}</p> */}
                </a>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Fragment>
    </div>
  );
};

export default BannerCarouselnew;
