import React, { Fragment, useState, useEffect } from "react";
import "../../pages/EventMain.css";

const PastEvents = (props) => {
  const [eventtwoData, setTwoData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/events/api/eventsectiontwo")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <div className="past_events pb-md-5 pt-3 mt-md-4">
      <Fragment>
        <div className="main-pad">
          <div className="d-flex">
            {eventtwoData && eventtwoData.length > 0 && (
              <>
                <h2> {eventtwoData[0].title3} </h2>
              </>
            )}
            <hr />
          </div>
          <div className="row my-md-5 my-4 w-100 ms-0">
            <div className="col-md-3 px-1 mb-2">
              <img src="img/Leasing/pe1.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
              <img src="img/Leasing/pe2.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
              <img src="img/Leasing/pe3.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
              <img src="img/Leasing/pe4.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
              <img src="img/Leasing/pe5.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
              <img src="img/Leasing/pe6.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
              <img src="img/Leasing/pe7.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
              <img src="img/Leasing/pe8.jpg" />
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default PastEvents;
