import React, { Fragment, useState, useEffect } from "react";
import "./Luxury.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import BannerCarousel1 from "../components/Common/BannerCarousel1";
import Help from "../components/Home/Help";
import LuxurylinkCategory from "../components/Luxury/LuxurylinkCategory";

const LuxuryCategory = (props) => {
  const [oneData, setOneData] = useState(null);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/luxurysectionone")
      .then((response) => response.json())
      .then((data) => setOneData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <Fragment>
      <div className="luxury_category">
        <Header />
        <Sidebar />
        {oneData && oneData.length > 0 && (
          <BannerCarousel1
            title1={oneData[0].titleone1}
            image1={`https://testadmin1.phoenixmallofasia.com${oneData[0].photo1_path}`}
            title2={oneData[0].titleone2}
            image2={`https://testadmin1.phoenixmallofasia.com${oneData[0].photo2_path}`}
            title3={oneData[0].titleone3}
            image3={`https://testadmin1.phoenixmallofasia.com${oneData[0].photo3_path}`}
            title4={oneData[0].titleone4}
            image4={`https://testadmin1.phoenixmallofasia.com${oneData[0].photo4_path}`}
            title5={oneData[0].titleone5}
            image5={`https://testadmin1.phoenixmallofasia.com${oneData[0].photo5_path}`}
          />
        )}
        {/* <BannerCarousel1
          title1="BURBERRY"
          para1="Arriving soon"
          image1="img/launch/BB.jpg"
          title2="COACH"
          para2="Arriving soon"
          image2="img/launch/Co.jpg"
          title3="LOUIS VUITTON"
          para3="Arriving soon"
          image3="img/launch/LV.jpg"
        /> */}
        <div className="culinary_adv1">
          {/*<div>
            <h4> A selection that <br/> leaves you spolit </h4>
            <h3> Choice</h3>
            <p> UPPER GROUND FLOOR </p>
  </div>*/}
        </div>
        <div className="newluxspace" style={{ padding: '30px' }}>
          {/* Content of Section 1 */}
        </div>
        <LuxurylinkCategory />
        <Help />
      </div>
    </Fragment>
  );
};

export default LuxuryCategory;
