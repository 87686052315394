import React, { Fragment, useState, useEffect } from "react";
import "./Help.css";
import { Link } from "react-router-dom";
import Button1 from "../Common/Button/Button1";
import axios from "axios";

const Help = (props) => {
  const [noteData, setNoteData] = useState(null);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get("https://testadmin1.phoenixmallofasia.com/pages/api/homesectionten");
        setNoteData(response.data[0]); // Assuming you only want the first item from the response array
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []); // Empty dependency array to ensure the effect runs only once

  if (!noteData) {
    return <div>Loading...</div>; // Display loading indicator while fetching data
  }

  const { id, maintitle, title, description, ctalink, ctatext, photo1_path } = noteData;
  return (
    <Fragment>
      <div className="help pt-md-4 pb-4">
        <div className="d-flex main-pad pe-0">
          <h2> {maintitle} </h2>
          <hr />
        </div>
        <div className="main-pad pb-md-4">
          <div className="row mt-xl-5 my-md-5 pt-md-4 pt-xl-3 mt-4 mb-xl-4 w-100 ms-0">
            <div className="col-md-6 px-0 pe-md-3">
              <img src={`https://testadmin1.phoenixmallofasia.com${photo1_path}`} />
              {/* <div className="gborder"></div> */}
            </div>
            <div className="col-md-6 px-0 px-md-3">
              <div className="text">
                <div>
                  <h3>{title}</h3>
                  <p>{description}</p>
                  <a href={ctalink} target="_blank">
                    <Button1 title={ctatext} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default Help;
