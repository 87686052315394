import React, { Fragment, useState, useEffect } from "react";
import "./Collection.css";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
const Collection = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://testadmin1.phoenixmallofasia.com/pages/api/newarrival");
      const filteredItems = response.data.filter(item => item.sections === "sectionthree");
      setItems(filteredItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div className="collection py-md-5 py-4">
      <Fragment>
        <div className="main-pad pe-0">
          <div className="d-flex mt-md-3">
            <h2> {items.length > 0 ? items[0].title : ""} </h2>
            <hr />
          </div>
        </div>
        <Carousel className="mt-md-5 mt-4">
          {items.reduce((accumulator, currentValue, index) => {
            if (index % 2 === 0) {
              accumulator.push(
                <Carousel.Item key={index}>
                  <div className="row">
                    <div className="col-md-6 px-1">
                      <div className="sect1">
                        <a href={`${currentValue.ctalink}`}>
                          <img src={`https://testadmin1.phoenixmallofasia.com${currentValue.gallery1_path}`} alt={`Cooking ${index + 1}`} />
                        </a>
                        <h3> {`${currentValue.ctatext}`} </h3>
                      </div>
                      {items[index + 1] && (
                        <div className="sect2 mt-md-1">
                          <a href={`${items[index + 1].ctalink}`}>
                            <img src={`https://testadmin1.phoenixmallofasia.com${items[index + 1].gallery1_path}`} alt={`Cooking ${index + 2}`} />
                          </a>
                          <h3> {`${items[index + 1].ctatext}`} </h3>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 px-0">
                      {items[index + 2] && (
                        <div className="sect3">
                          <a href={`${items[index + 2].ctalink}`}>
                            <img src={`https://testadmin1.phoenixmallofasia.com${items[index + 1].gallery1_path}`} alt={`Cooking ${index + 3}`} />
                          </a>
                          <h3> {`${items[index + 2].ctatext}`} </h3>
                        </div>
                      )}
                    </div>
                  </div>
                </Carousel.Item>
              );
            }
            return accumulator;
          }, [])}


        </Carousel>
      </Fragment>
    </div>
  );
};

export default Collection;
