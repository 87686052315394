import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./ServiceTab.css";
import ServiceHead from "./ServiceHead";
import MallServices from "./MallServices";

const ServiceCurb = (props) => {
  const [servicetwoData, setTwoData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/servicesectiontwo")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <Fragment>

      <div className="service_curb">
        {servicetwoData && servicetwoData.length > 0 && (
          <>
            <ServiceHead
              title={servicetwoData[0].title1}
              para={servicetwoData[0].desc1}
              image={`https://testadmin1.phoenixmallofasia.com${servicetwoData[0].photo1_path}`}
            />

            <Container>
              <div className="curb_cards pb-md-5">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <div>
                        <img src={`https://testadmin1.phoenixmallofasia.com${servicetwoData[0].photo2_path}`} />
                      </div>
                      <h4> {servicetwoData[0].title2} </h4>
                      <p>
                        {servicetwoData[0].desc2}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div>
                        <img src="img/Services/time.png" />
                      </div>
                      <h4> {servicetwoData[0].title3} </h4>
                      <p>
                        {servicetwoData[0].desc3}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div>
                        <img src="img/Services/package.png" />
                      </div>
                      <h4> {servicetwoData[0].title4} </h4>
                      <p>
                        {servicetwoData[0].desc4}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </>
        )}
        <MallServices />
      </div>
    </Fragment>
  );
};

export default ServiceCurb;
