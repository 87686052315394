import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Mall.css";

function MallModal({ id, img, fimg }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addBanner = () => {
    dispatchEvent({
      item: {
        id: id,
        img: img,
        fimg: fimg
      },
    });
  };
  return (
    <>
      <div className="row mall w-100 ms-0">
        <div className="col-md-12">
          <div className="image-box">
            <Button variant="primary" onClick={handleShow}>
              <img src={img} />
            </Button>

            <Modal show={show} onHide={handleClose}>
              <img src={fimg} />
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default MallModal;
