import React, { Fragment, useState, useEffect } from "react";
import "./BlogCategory.css";
import Card13 from "../Common/Card/Card13";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';
import Button1 from "../Common/Button/Button1";

const BlogCategory = ({ props }) => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [brand, setBrand] = useState(null);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [titleFilter, setTitleFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [visibleItemCount, setVisibleItemCount] = useState(4);
  const itemsToAdd = 4;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://testadmin1.phoenixmallofasia.com/events/api/blogslist');
        const jsonData = await response.json();
        setData(jsonData);

        // Extract unique categories from the data
        const uniqueCategories = [...new Set(jsonData.map((brand) => brand.category))];
        setUniqueCategories(uniqueCategories);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/blog/${id}`);
        setBrand(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setBrand(null);
      }
    }
    fetchUserData();
  }, [id]);

  const handleTitleFilter = (filterValue) => {
    setTitleFilter(filterValue);
  };

  const handleCategoryFilter = (filterValue) => {
    setCategoryFilter(filterValue);
  };

  const handleFilterSelection = (filter) => {
    setSelectedFilter(filter);
  };

  const filteredBrands = data.filter((brand) => {
    const categoryMatch = categoryFilter === '' || brand.category === categoryFilter;
    const titleMatch = titleFilter === '' || brand.title.toLowerCase().includes(titleFilter.toLowerCase());
    const filterMatch = selectedFilter === '' || checkFilterRange(brand.title[0], selectedFilter);

    return categoryMatch && titleMatch && filterMatch;
  });

  // Create a helper function to check if a character falls within a filter range
  function checkFilterRange(character, filter) {
    switch (filter) {
      case 'A-E':
        return 'A' <= character && character <= 'E';
      case 'F-J':
        return 'F' <= character && character <= 'J';
      case 'K-O':
        return 'K' <= character && character <= 'O';
      case 'P-T':
        return 'P' <= character && character <= 'T';
      case 'U-Z':
        return 'U' <= character && character <= 'Z';
      default:
        return true; // No filter selected, so show all
    }
  }

  return (
    <div className="blog_category brand_view button py-md-5 pt-4">
      <Fragment>
        <div className="main-pad pt-md-5">
          <div className="row px-md-0 px-2">
            {filteredBrands.length === 0 ? (
              <p>No matches found.</p>
            ) : (
              <div className="row px-md-0 px-2 ms-0">
                {filteredBrands.slice(0, visibleItemCount).map((brand) => (
                  <div className="col-md-3 col-6 px-md-3 px-1" key={brand.id}>
                    <a href={`/blog/${brand.title}`}>
                      <Card13
                        image={`https://testadmin1.phoenixmallofasia.com${brand.photo1_path}`}
                        title={brand.title}
                        para={brand.about}
                        author={brand.author}
                        date={brand.date}
                        time={brand.timing2}
                      />
                    </a>
                  </div>
                ))}
              </div>
            )}


            {filteredBrands.length > visibleItemCount && (
              <button onClick={() => setVisibleItemCount(prevCount => prevCount + itemsToAdd)}>
                View More
              </button>
            )}
            {/* <div className="col-md-4 col-12 px-md-3 px-1">
              <a href="/blog-detail">
                <Card13
                  image={"img/Blog/b1.png"}
                  title=" Lorem ipsum dolor sit amet, Lorem ipsu consectetur "
                  para="Did you come here for something in particular or just general Riker-bashing? And blowing into"
                  author="Jenny Kiaa"
                  date="02 December 2022"
                  time="3 Min To Read"
                />
              </a>
            </div>
            <div className="col-md-4 col-12 px-md-3 px-1">
              <a href="/blog-detail">
                <Card13
                  image={"img/Blog/b2.png"}
                  title=" Lorem ipsum dolor sit amet, Lorem ipsu consectetur "
                  para="Did you come here for something in particular or just general Riker-bashing? And blowing into"
                  author="Jenny Kiaa"
                  date="02 December 2022"
                  time="3 Min To Read"
                />
              </a>
            </div>
            <div className="col-md-4 col-12 px-md-3 px-1">
              <a href="/blog-detail">
                <Card13
                  image={"img/Blog/b3.png"}
                  title=" Lorem ipsum dolor sit amet, Lorem ipsu consectetur "
                  para="Did you come here for something in particular or just general Riker-bashing? And blowing into"
                  author="Jenny Kiaa"
                  date="02 December 2022"
                  time="3 Min To Read"
                />
              </a>
            </div> */}

          </div>
          {/* <Nav.Link href="/brand-detail">
            {" "}
            <Button1 title="VIEW MORE" />{" "}
          </Nav.Link> */}
        </div>
      </Fragment>
    </div>
  );
};

export default BlogCategory;
