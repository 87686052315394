import React, { Fragment, useState, useEffect } from "react";
import LeisureGames from "../Leisure/LeisureGames";
import LeisureInox from "../Leisure/LeisureInox";
import LeisurePark from "../Leisure/LeisurePark";
import LeisureSpa from "../Leisure/LeisureSpa";
import "./TabBanner.css";

const content = [
  [<LeisureGames />],
  [<LeisureInox />],
  [<LeisureSpa />],
  [<LeisurePark />],
];

export default function LeisureTab() {
  const [activeContentIndex, setActiveContentIndex] = useState(0);
  const [firstleisureData, setTabData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/leisuresectionfirst")
      .then((response) => response.json())
      .then((data) => setTabData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <div className="tab_banner pt-md-5">
      <div className="mt-md-5 pt-md-4" id="tabs">
        <menu>
          {firstleisureData && firstleisureData.length > 0 && (
            <div className="row w-100 ms-0">
              <div className="col-md-3 col-6 px-0">
                <button
                  className={activeContentIndex === 0 ? "active" : ""}
                  onClick={() => setActiveContentIndex(0)}
                >
                  <img src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto1_path}`} />
                  <div className="overlay"></div>
                  <div className="text">
                    <h1> {firstleisureData[0].firsttitle1} </h1>
                    <img className="normal" src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto2_path}`} />
                    <img className="action" src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto2_path}`} />
                  </div>
                </button>
              </div>
              <div className="col-md-3 col-6 px-0">
                <button
                  className={activeContentIndex === 1 ? "active" : ""}
                  onClick={() => setActiveContentIndex(1)}
                >
                  <img src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto3_path}`} />
                  <div className="overlay"></div>
                  <div className="text">
                    <h1> {firstleisureData[0].firsttitle2}S</h1>
                    <img className="normal" src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto4_path}`} />
                    <img className="action" src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto4_path}`} />
                  </div>
                </button>
              </div>
              <div className="col-md-3 col-6 px-0">
                <button
                  className={activeContentIndex === 2 ? "active" : ""}
                  onClick={() => setActiveContentIndex(2)}
                >
                  <img src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto5_path}`} />
                  <div className="overlay"></div>
                  <div className="text">
                    <h1> {firstleisureData[0].firsttitle3} </h1>
                    <img className="normal" src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto6_path}`} />
                    <img className="action" src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto6_path}`} />
                  </div>
                </button>
              </div>
              <div className="col-md-3 col-6 px-0">
                <button
                  className={activeContentIndex === 3 ? "active" : ""}
                  onClick={() => setActiveContentIndex(3)}
                >
                  <img src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto7_path}`} />
                  <div className="overlay"></div>
                  <div className="text">
                    <h1>{firstleisureData[0].firsttitle4}</h1>
                    <img className="normal" src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto8_path}`} />
                    <img className="action" src={`https://testadmin1.phoenixmallofasia.com${firstleisureData[0].firstphoto8_path}`} />
                  </div>
                </button>
              </div>
            </div>
          )}
        </menu>
        <div id="tab-content">
          <ul>
            {content[activeContentIndex].map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
