import React, { useState, useEffect, Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Memoirs.css";
import { Link } from "react-router-dom";
import Button1 from "../Common/Button/Button1";
import Card2 from "../Common/Card/Card2";
import axios from "axios";

const Memoirs = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(
          "https://testadmin1.phoenixmallofasia.com/events/api/pastbloghome"
        );
        setOffers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching offers:", error);
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  const [homeeightData, setEightData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/homesectioneight")
      .then((response) => response.json())
      .then((data) => setEightData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="memoirs pb-md-2 pt-md-0 pb-4">
      <div className="d-flex main-pad pe-0">
        {
          homeeightData && homeeightData.length > 0 && (
            <>
              <h2> {homeeightData[0].title8} </h2>
            </>
          )
        }
        <hr />
      </div>
      <div className="main-pad">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="container-fluid pt-md-0 px-0">
            <OwlCarousel
              items={3}
              className="owl-theme mt-md-5  mt-3"
              loop
              nav
              autoplay
              margin={25}
              responsive={{
                0: { items: 1 },
                450: { items: 1 },
                600: { items: 3 },
                1000: { items: 3 },
              }}
            >
              {offers.map((offer) => (
                <div key={offer.id}>
                  <a href={`/blog/${offer.title}`}>
                    <Card2
                      image={`https://testadmin1.phoenixmallofasia.com${offer.photo2_path}`} // Assuming photo2_path contains image URLs
                      title={offer.title}
                      // subtitle={offer.packagestatus === "Offer"}
                      para={`${offer.date} to ${offer.expdate}`}
                    />
                  </a>
                </div>
              ))}
            </OwlCarousel>
          </div>
        )}
        <div className="view-btn mt-md-5">
          {/* <Link to="/blogs"> 
            <Button1 title="READ MORE" />
          </Link> */}
          {
            homeeightData && homeeightData.length > 0 && (
              <>
                <Link to={homeeightData[0].ctalink8}>
                  <Button1 title={homeeightData[0].ctatext8} />
                </Link>

              </>
            )
          }
        </div>
      </div>
    </div>
  );
}


export default Memoirs;
