import React, { Fragment } from "react";
import "./Card7.css";
import Button2 from "../Button/Button2";
import { BsPlayCircle } from "react-icons/bs";

const MovieCard = ({ id, title, para, subtitle, image, playurl, ticketlink }) => {
  const addBanner = () => {
    dispatchEvent({
      item: {
        id: id,
        title: title,
        para: para,
        subtitle: subtitle,
        image: image,
        playurl: playurl,
        ticketlink: ticketlink
      },
    });
  };
  return (
    <div className="movie_card pt-md-5 pb-md-4 pt-3">
      <Fragment>
        <div className="card">
          <div>
            <img src={image} />
          </div>

          <div className="overlay">
            <div>
              <div className="play text-center mb-md-4 mb-3">
                <a href={playurl}>
                  <BsPlayCircle />
                </a>
              </div>
              <h4> {title} </h4>
              <a href={ticketlink}>
                <Button2 title="BOOK NOW" />
              </a>
            </div>
          </div>
        </div>
        <div className="text py-md-3 py-3">
          <h3> {title}</h3>
          <p>{para} </p>
        </div>
      </Fragment>
    </div>
  );
};

export default MovieCard;
