import React, { Fragment } from "react";
import "./responsive.css";
import "./Leasing.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import Carousel1 from "../components/Common/Carousel/Carousel1";
import Form1 from "../components/Common/Form1";
import PastEvents from "../components/Events/PastEvent";
import LeaseInfo from "../components/Leasing/LeaseInfo";

const Leasing = (props) => {
  return (
    <Fragment>
      <div className="leasing">
        <Header />
        <Sidebar />
        <Banner image="img/Leasing/banner.jpg" />
        <LeaseInfo
          title="ABOUT THE SPACE"
          para="Phoenix Mall Of Asia Bangalore, a part of Phoenix Mills Limited is one of the biggest malls in Asia. Phoenix Mall Of Asia was built with the objective of providing unique experiences to its customers. Phoenix Mall Of Asia covers over 300 leading brands in its prime retail and leisure space. Built in an area encompassing 1.25 million square meter the mall boasts of an assorted tenant mix –INOX screens, over 10 large anchor tenants and brands like Zara, Lifestyle, Marks & Spencer, Pantaloons, Trends, Max, Tommy Hilfiger, Vero Moda, Calvin Klein Jeans, Mango, ForeverNew, Lacoste, Only, Taneira, ALDO, Charles & Keith, Da Milano, HiDesign,Enamor, BobbiBrown, Mac, Forest Essentaisl, Health & Glow, Tanishq, Malabar Gold, and many others making it the most happening destination in Bangalore. Over the years our pioneering vision and dynamic efforts have been globally recognised with business innovation and excellence awards."
        />
        {/* <Categories /> */}
        {/*<Carousel1 title="FEATURED OUTLETS" image1="img/Leasing/1.jpg" image2="img/Leasing/2.jpg"  image3="img/Leasing/1.jpg"  image4="img/Leasing/2.jpg"  image5="img/Leasing/1.jpg"/>*/}
        <br />
        <PastEvents />
        <Form1
          tag="Leasing"
          title="Have a question? Connect with us!"
          para="Write to us and we will reach out shortly."
        />
      </div>
    </Fragment>
  );
};

export default Leasing;
