import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
// import "./EventMain.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import LaunchBanner from "../assets/Home/rickeybanner.jpg";
import EventCard from "../components/Events/EventCard";
import Form1 from "../components/Common/Form1";
import EventInfo from "../components/Events/EventInfo";
import { useParams } from 'react-router-dom'; // Import useParams
import axios from 'axios';

const EventDetails = (props) => {

  const { id } = useParams(); // Use useParams to access the 'id' parameter
  const [event, setEvents] = useState(null);


  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/event/${id}`);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setEvents(null);
      }
    }

    fetchUserData();
  }, [id]);

  return (
    <Fragment>
      <div className="event_details">
        <Header />
        <Sidebar />
        {event && (
          <>
            <Banner image={`https://testadmin1.phoenixmallofasia.com${event.photo2_path}`} />
          </>
        )}
        <EventInfo />
        {/* <Nhance /> */}
        <div className="main-pad">
          <div className="d-flex">
            <h2 className=""> UPCOMING EVENTS </h2>
            <hr />
          </div>
        </div>
        <EventCard />
        <Form1 tag="Eventpage"
          title="Do you have any queries about the events?"
          para="Write to us and we’ll get back to you!"
        />
      </div>
    </Fragment>
  );
};

export default EventDetails;
