import React, { Fragment, useEffect, useState } from "react";
import "./Luxury.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import Form1 from "../components/Common/Form1";
import LuxuryBanner from "../assets/Luxury/banner.jpg";
import Glory from "../assets/Luxury/glory.jpg";
import LuxPageCategory from "../components/Luxury/LuxPageCategory";
import LuxCategory from "../components/Luxury/LuxCategory";
import Carousel1 from "../components/Common/Carousel/Carousel1";
import Carousel4 from "../components/Common/Carousel/Carousel4";
import LuxEvents from "../components/Luxury/LuxEvents";

const Luxury = (props) => {
  const [luxtwoData, setTwoData] = useState(null);
  const [luxthreeData, setThreeData] = useState(null);
  const [luxfourData, setFourData] = useState(null);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/luxurysectionone")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/luxurysectiontwo")
      .then((response) => response.json())
      .then((data) => setThreeData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/luxurysectionthree")
      .then((response) => response.json())
      .then((data) => setFourData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <Fragment>
      <div className="luxury">
        <Header />
        <Sidebar />
        {/* <Banner
          title="sss"
          para=""
          image={LuxuryBanner}
        /> */}
        {luxtwoData && luxtwoData.length > 0 && (
          <Carousel4
            image1={`https://testadmin1.phoenixmallofasia.com${luxtwoData[0].photo1_path}`}
            image2={`https://testadmin1.phoenixmallofasia.com${luxtwoData[0].photo2_path}`}
            image3={`https://testadmin1.phoenixmallofasia.com${luxtwoData[0].photo3_path}`}
            image4={`https://testadmin1.phoenixmallofasia.com${luxtwoData[0].photo4_path}`}
            image5={`https://testadmin1.phoenixmallofasia.com${luxtwoData[0].photo5_path}`}
          />
        )}
        <br />
        <LuxPageCategory />
        <LuxCategory />
        {luxthreeData && luxthreeData.length > 0 && (
          <div className="jewellery_watch">
            <img src={`https://testadmin1.phoenixmallofasia.com${luxthreeData[0].photo1_path}`} />
            <div className="text">
              <h2>
                {luxthreeData[0].title} <br /> <span>{luxthreeData[0].title}</span>
              </h2>
              <a href={luxthreeData[0].ctalink}>
                <button> {luxthreeData[0].ctatext} </button>
              </a>
            </div>
          </div>
        )}
        {luxfourData && luxfourData.length > 0 && (
          <>
            <Carousel1
              image1={`https://testadmin1.phoenixmallofasia.com${luxfourData[0].photo1_path}`}
              image2={`https://testadmin1.phoenixmallofasia.com${luxfourData[0].photo2_path}`}
              image3={`https://testadmin1.phoenixmallofasia.com${luxfourData[0].photo3_path}`}
              image4={`https://testadmin1.phoenixmallofasia.com${luxfourData[0].photo4_path}`}
              image5={`https://testadmin1.phoenixmallofasia.com${luxfourData[0].photo5_path}`}
            />

            <div className="culinary_adv">
              <div>
                {/* <h4> Coming Soon </h4>
                <h4> ENRICHING YOUR </h4>
                <h3> Culinary</h3>
                <p> ADVENTURE </p> */}
                <h4> {luxfourData[0].title} </h4>
                {/* <h4> {luxfourData[0].title2} </h4> */}
                <h3> {luxfourData[0].title2}</h3>
                <p>{luxfourData[0].title3} </p>
                <a href={luxthreeData[0].ctalink}>
                  {" "}
                  <button type="button"> {luxthreeData[0].ctatext} </button>{" "}
                </a>
              </div>
            </div>
          </>
        )}
        <LuxEvents />
        <Form1 tag="Luxury"
          title="Luxury is a part of our identity."
          para="If you have any questions, ask us here!"
        />
      </div>
    </Fragment>
  );
};

export default Luxury;
