import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Help from "../Home/Help";
import Card5 from "../Common/Card/Card5";

const LeisureGames = (props) => {
  const [leisureoneData, setTwoData] = useState(null);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/leisuresectionone")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <Fragment>
      <div className="leisure_games mt-md-4">
        <div className="main">
          {leisureoneData && leisureoneData.length > 0 && (
            <Container>
              <Card5
                title={leisureoneData[0].title1}
                para={leisureoneData[0].desc1}
                link={leisureoneData[0].ctalink1}
                // link="/brand/Funcity"
                button={leisureoneData[0].ctatext1}
                image={`https://testadmin1.phoenixmallofasia.com${leisureoneData[0].photo1_path}`}
              />
              <div className="reverse">
                <Card5
                  title={leisureoneData[0].title2}
                  para={leisureoneData[0].desc2}
                  link={leisureoneData[0].ctalink2}
                  // link="/brand/Timezone"
                  button={leisureoneData[0].ctatext2}
                  image={`https://testadmin1.phoenixmallofasia.com${leisureoneData[0].photo2_path}`}
                />
              </div>
              <Card5
                title={leisureoneData[0].title3}
                para={leisureoneData[0].desc3}
                link={leisureoneData[0].ctalink3}
                // link="#"
                button={leisureoneData[0].ctatext3}
                image={`https://testadmin1.phoenixmallofasia.com${leisureoneData[0].photo3_path}`}
              />
            </Container>
          )}
        </div>
        {/* <Nhance /> */}
        <Help />
      </div>
    </Fragment>
  );
};

export default LeisureGames;
