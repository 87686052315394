import React, { Fragment, useEffect, useState } from "react";
import "./EventInfo.css";
import Container from "react-bootstrap/esm/Container";
import Button2 from "../Common/Button/Button2";
import { useParams } from 'react-router-dom'; // Import useParams
import axios from 'axios';

const EventInfo = () => {

  const { id } = useParams(); // Use useParams to access the 'id' parameter
  const [event, setEvents] = useState(null);


  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/event/${id}`);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setEvents(null);
      }
    }

    fetchUserData();
  }, [id]);

  return (
    <div className="event_info py-md-5 pt-3 mt-md-4">
      <Fragment>
        <Container>
          {event && (
            <>
              <div className="top d-flex w-100">
                <div>
                  <h3>{event.title}</h3>
                  <p>{event.artist} | {event.language}</p>
                </div>
                <div className="ms-auto">
                  <a href={event.booking} target="_blank" >
                    <Button2 title="BOOK" />
                  </a>
                </div>
              </div>
              <div className="card shadow">
                <div className="row">
                  <div className="col-md-4 col-4 text-center">
                    <img src="/img/Events/date.png" alt="Date" />
                    <p className="mt-md-4 mt-3">{event.date}</p>
                  </div>
                  <div className="col-md-4 col-4 text-center">
                    <img src="/img/Events/time.png" alt="Time" />
                    <p className="mt-md-4 mt-3">{event.eventtiming}</p>
                  </div>
                  <div className="col-md-4 col-4 text-center">
                    <img src="/img/Events/location.png" alt="Location" />
                    <p className="mt-md-4 mt-3">{event.venue}</p>
                  </div>
                </div>
              </div>
              <p>{event.description}</p>
              <div className="row my-md-5">
                <div className="col-md-4 px-1 mb-1">
                  <img src={`https://testadmin1.phoenixmallofasia.com${event.gallery2_path}`} alt="Event Image" />
                </div>
                <div className="col-md-4 px-1 mb-1">
                  <img src={`https://testadmin1.phoenixmallofasia.com${event.gallery3_path}`} alt="Event Image" />
                </div>
                <div className="col-md-4 px-1 mb-1">
                  <img src={`https://testadmin1.phoenixmallofasia.com${event.gallery4_path}`} alt="Event Image" />
                </div>
                {/* <div className="col-md-4 px-1">
                  {/* Add more images if available */}
                {/*  </div> */}
              </div>
              <div className="tc py-3">
                <h3>Terms and Conditions</h3>
                <ul className="mt-md-4 mt-3">
                  <li>{event.conditions}</li>
                  {/* Add more terms and conditions if available */}
                </ul>
              </div>
            </>
          )}
        </Container>
      </Fragment>
    </div>
  );
};

export default EventInfo;
