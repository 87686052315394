import React, { Fragment } from "react";
import "./Carousel3.css";
import Container from "react-bootstrap/esm/Container";
import Carousel from "react-bootstrap/Carousel";
import Button2 from "../Button/Button2";

const Carousel3 = ({ title, items }) => {
  return (
    <div className="carousel3 pt-md-5">
      <Fragment>
        <div className="main-pad">
          <div className="d-flex pt-md-4">
            <h2> {title} </h2>
            <hr />
          </div>
        </div>
        <Carousel className="mt-md-5 mt-3">
          {items.map((item) => (
            <Carousel.Item key={item.id}>
              <img src={item.image} alt={item.title} />
              <div className="text">
                <Container>
                  <h3>{item.subtitle}</h3>
                  <a href={item.ticketlink}>
                    <Button2 title={item.button} />
                  </a>
                </Container>
              </div>
            </Carousel.Item>

          ))}
        </Carousel>
      </Fragment>
    </div>
  );
};

export default Carousel3;
