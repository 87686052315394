import React, { useEffect, useRef, Fragment } from 'react';
import "./StayConnect.css";

const InstagramFeed = () => {
  const sliderRef = useRef(null);

  const useScript = (url) => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, [url]);
  };

  useScript('https://cdn.jsdelivr.net/gh/stevenschobert/instafeed.js@2.0.0rc1/src/instafeed.min.js');

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -sliderRef.current.offsetWidth / 3,
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.offsetWidth / 3,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    const scriptLoaded = () => {
      if (window.Instafeed) {
        const userFeed = new window.Instafeed({
          get: 'user',
          target: 'instafeed-container',
          resolution: 'low_resolution',
          accessToken: 'IGQWRNTVcwNlBqTFphOV9zU2t5ZAWtYamNRcHhBY182eENuQkptZAkpPNC0xSE5DNV83TXJPMkxZAQ1VoNVlHcW9mVHo1OXFoZA0NVeGcxbEUyckFlcVUxWGZAxTWVDRTVhWGE4ZA0RDa2hlZAGlzclVVd21WTUhKM2V6cm8ZD',
          limit: 12, // Adjust this number as needed for the number of images
          template: '<div class="slide"><a href="{{link}}" target="_blank"><img src="{{image}}" /></a></div>',
        });
        userFeed.run();
      }
    };

    document.addEventListener('load', scriptLoaded, true);

    return () => {
      document.removeEventListener('load', scriptLoaded, true);
    };
  }, []);

  return (
    <Fragment>
      <div className="connect pt-md-4 pb-md-0 pb-4">
        <div className="d-flex main-pad pe-0">
          <h2> STAY CONNECTED </h2>
          <hr />
        </div>
        <div className="main-pad">
          <div class="container-fluid pt-md-4 px-0">
            <div className="instagram-feed-container">
              <div className="instagram-feed-slider" ref={sliderRef}>
                <div className="slider" id="instafeed-container"></div>
              </div>
              <button className="arrow-button left-arrow" onClick={scrollLeft}>
                &lt;
              </button>
              <button className="arrow-button right-arrow" onClick={scrollRight}>
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InstagramFeed;
