import React, { Fragment } from "react";
import "./Launch.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Carousel1new from "../components/Common/Carousel/Carousel1new";
import Carousel4new from "../components/Common/Carousel/Carousel4new";
import Collectionnew from "../components/Launch/Collectionnew";
import Cookingnew from "../components/Launch/Cookingnew";
import Form1 from "../components/Common/Form1";
import BannerCarouselNew from "../components/Common/BannerCarouselnew";

const Launch = (props) => {
  return (
    <Fragment>
      <div className="launch">
        <Header />
        <Sidebar />
        <BannerCarouselNew
        // title1="Zara"
        // para1="Coming soon to Mall of Asia. Stay tuned."
        // image1="img/launch/Zara.png"
        // Link1="/brand/Calvin%20Klein"
        // title2="INOX"
        // para2="Coming soon to Mall of Asia. Stay tuned."
        // image2="img/launch/inox.jpeg"
        // Link2="/brand/D&G%20Cosmetics"
        // title3="La Martina"
        // para3="Coming soon to Mall of Asia. Stay tuned."
        // image3="img/launch/Dune.jpg"
        // Link3="/brand/Allen%20Solly"
        // title4="Hunke Moller"
        // para4="Coming soon to Mall of Asia. Stay tuned."
        // image4="img/launch/hunkmoller.jpeg"
        // Link4="/brand/Nike"
        />
        <Carousel1new
        // title="MOA ARRIVALS"
        // title1="Charles & Keith opens one of a kind store in mall of asia."
        // image1="img/launch/Moaarraive banner 1.jpg"
        // Link1="/brand/Charles%20&%20Keith"
        // title2="Forever New opens one of a kind store in mall of asia."
        // image2="img/launch/4.jpg"
        // Link2="/brand/Forever%20New"
        // title3="Vanhausen Women opens one of a kind store in mall of asia."
        // image3="img/launch/arrivals3.jpg"
        // Link3="/brand/VH%20Women"
        // title4="Tommy Hilfiger Kids & USPA kids opens one of a kind store in mall of asia."
        // image4="img/launch/THK+USPAK.jpg"
        // Link4="/brand/Tommy%20Kids%20+%20USPA%20Kids"
        // image5="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/11/Tira-Beauty-copy.jpg"
        // Link5="/brand/Tira"
        />
        <Collectionnew />
        <div className="coming_soon">
          <Carousel4new
          // title="COMING SOON"
          // image1="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/09/Moaarraive-banner-3.png"
          // image2="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/09/Moaarraive-banner-4.png"
          // image3="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/11/Axorte-copy.jpg"
          // image4="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/09/Senco.jpg"
          />
        </div>
        <Cookingnew />
        <Form1 tag="Launch"
          title="Something Significant is on its Way to You"
          para="Write to us and we will reach out shortly."
        />
      </div>
    </Fragment>
  );
};

export default Launch;
