import React, { Fragment, useState, useEffect } from "react";
import Card3 from "../Common/Card/Card3";
import Brand1 from "../../assets/Brands/Tony-and-Guy.png";
import Brand2 from "../../assets/Brands/playsalon.jpg";
import Brand3 from "../../assets/Brands/eyecatcher.png";
import Carousel1 from "../Common/Carousel/Carousel1";
import axios from 'axios';
import { useParams } from 'react-router-dom';

const LeisureSpa = (props) => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [brand, setBrand] = useState(null);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [titleFilter, setTitleFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('Spa & Salon');

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://testadmin1.phoenixmallofasia.com/api/brand');
        const jsonData = await response.json();
        setData(jsonData);

        // Extract unique categories from the data
        const uniqueCategories = [...new Set(jsonData.map((brand) => brand.category))];
        setUniqueCategories(uniqueCategories);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/brand/${id}`);
        setBrand(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setBrand(null);
      }
    }
    fetchUserData();
  }, [id]);
  const filteredBrands = data.filter((brand) => {
    const categoryMatch = categoryFilter === '' || brand.category === categoryFilter;
    const titleMatch = titleFilter === '' || brand.title.toLowerCase().includes(titleFilter.toLowerCase());

    return categoryMatch && titleMatch;
  });
  const [spatwoData, setTwoData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/spasectiontwo")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <Fragment>
      <div className="leisure_spa mt-md-4 mt-3">
        <div className="main-pad">
          <div className="row px-md-0 px-2">
            <div className="row px-md-0 px-2 ms-0">
              {filteredBrands.map((brand) => (
                <div className="col-md-3 col-6 px-md-3 px-1" key={brand.id}>
                  <a href={`/brand/${brand.title}`}>
                    <Card3
                      category={brand.category}
                      image={brand.photo1_path}
                      location={brand.contact}
                      floor={brand.floor}
                    />
                  </a>
                </div>
              ))}
            </div>
            {/* <div className="col-md-4 col-6 px-md-3 px-1">
              <a href="/brand/Toni%20&%20Guy">
                <Card3
                  image={Brand1}
                  location="The Abcd Zone"
                  floor="First Floor"
                />
              </a>
            </div>
            <div className="col-md-4 col-6 px-md-3 px-1">
              <a href="/brand/Play%20Salon">
                <Card3
                  image={Brand2}
                  location="The Abcd Zone"
                  floor="Ground Floor"
                />
              </a>
            </div>
            <div className="col-md-4 col-6 px-md-3 px-1">
              <a href="/brand/Eyecatchers">
                <Card3
                  image={Brand3}
                  location="The Abcd Zone"
                  floor="Third Floor"
                />
              </a>
            </div> */}
          </div>
        </div>
        {/* <Carousel3 image="img/Leisure/upcoming.png" /> */}
        {spatwoData && spatwoData.length > 0 && (
          <Carousel1
            image1={`https://testadmin1.phoenixmallofasia.com${spatwoData[0].photo1_path}`}
            image2={`https://testadmin1.phoenixmallofasia.com${spatwoData[0].photo2_path}`}
            image3={`https://testadmin1.phoenixmallofasia.com${spatwoData[0].photo3_path}`}
            image4={`https://testadmin1.phoenixmallofasia.com${spatwoData[0].photo4_path}`}
            image5={`https://testadmin1.phoenixmallofasia.com${spatwoData[0].photo5_path}`}
          />
        )}
      </div>
    </Fragment>
  );
};

export default LeisureSpa;
