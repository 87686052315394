import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import "../../pages/EventMain.css";
import Card6 from "../Common/Card/Card6";

const EventCard = (props) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/events/api/eventhome")
      .then((response) => response.json())
      .then((data) => {
        setEvents(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  const [eventtwoData, setTwoData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/events/api/eventsectiontwo")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="event_card pt-md-5 pt-3 mt-md-4">
      <Fragment>
        <div className="main-pad">
          <div className="d-flex">
            {eventtwoData && eventtwoData.length > 0 && (
              <>
                <h2> {eventtwoData[0].title2} </h2>
              </>
            )}
            <hr />
          </div>

          <div className="row">
            {events.slice(0, 2).map((event) => (
              <div key={event.id} className="col-md-4">
                <Link to={`/event/${event.title}`}> {/* Use Link instead of anchor */}
                  <Card6
                    title={event.title}
                    para={`${event.date} | ${event.eventtiming}`}
                    image={`https://testadmin1.phoenixmallofasia.com${event.photo1_path}`} // Assuming this is the image path from the API
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default EventCard;
