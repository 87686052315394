import React, { Fragment, useState, useEffect } from "react";
import "./Luxury.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import LuxuryCategorymore from "../components/Luxury/LuxuryCategorymore";
import BrandHead from "../components/Brands/BrandHead";
import Form1 from "../components/Common/Form1";
import Brand1 from "../assets/Brands/brooks-logo.png";
import Image1 from "../assets/Brands/image1.png";
import { useParams } from 'react-router-dom'; // Import useParams
import axios from 'axios';


const LuxuryDetails = (props) => {
  const { id } = useParams(); // Use useParams to access the 'id' parameter
  const [luxury, setLuxury] = useState(null);


  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/luxury/${id}`);
        setLuxury(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setLuxury(null);
      }
    }

    fetchUserData();
  }, [id]);
  return (
    <Fragment>
      <div className="brand_detail luxury_detail">
        <Header />
        <Sidebar />
        {luxury && (
          <>
            <Banner
              title={luxury.title}
              image={`https://testadmin1.phoenixmallofasia.com${luxury.photo2_path}`}
              para={luxury.desctext}

            />
            <BrandHead
              title={luxury.title}
              floor={luxury.floor}
              sub1={luxury.floor}
              box="LUXURY CATALOGUE"
              logo={`https://testadmin1.phoenixmallofasia.com${luxury.photo1_path}`}
              subtitle={luxury.abouttitle}
              para={luxury.about}
              t1={luxury.contact}
              t2={luxury.email}
              t3={luxury.timing}
            />

            {/* <Carousel2 /> */}
            <div className="main-pad py-md-5 py-4">
              <div className="row mt-md-4">
                <div className="col-md-3 col-12">
                  <img src={`https://testadmin1.phoenixmallofasia.com${luxury.gallery1_path}`} />
                </div>
                <div className="col-md-3 col-12">
                  <img src={`https://testadmin1.phoenixmallofasia.com${luxury.gallery2_path}`} />
                </div>
                <div className="col-md-3 col-12">
                  <img src={`https://testadmin1.phoenixmallofasia.com${luxury.gallery3_path}`} />
                </div>
                <div className="col-md-3 col-12">
                  <img src={`https://testadmin1.phoenixmallofasia.com${luxury.gallery4_path}`} />
                </div>
              </div>
            </div>
            <div className="main-pad pe-0 pb-4 pb-md-0">
              <div className="d-flex mt-xl-4 mt-0">
                <h2> More Like This </h2>
                <hr />
              </div>
            </div>
          </>
        )}
        <LuxuryCategorymore />
        {/* <Nhance /> */}
        {/* <Notes /> */}
        <Form1 tag="LuxuryDetails" title="Want to know more about this brand?" />
      </div>
    </Fragment>
  );
};

export default LuxuryDetails;
