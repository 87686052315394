import React, { Fragment, useEffect, useState } from "react";
import "./LuxCategory.css";
import Container from "react-bootstrap/esm/Container";
import Card3lux from "../Common/Card/Card3lux";
import Carousel1 from "../Common/Carousel/Carousel1";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link, useLocation } from "react-router-dom";


function LuxCategory() {
  const [brandData, setBrandData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [brand, setBrand] = useState(null);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [titleFilter, setTitleFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const location = useLocation(); // Use useLocation to access the current location

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://testadmin1.phoenixmallofasia.com/api/luxbrandlux');
        const jsonData = await response.json();
        setData(jsonData);
        // Extract unique categories from the data
        const uniqueCategories = [...new Set(jsonData.map((brand) => brand.category))];
        setUniqueCategories(uniqueCategories);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/api/luxbrandlux/${id}`);
        setBrand(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setBrand(null);
      }
    }
    fetchUserData();
  }, [id]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://testadmin1.phoenixmallofasia.com/api/luxgroup")
      .then((response) => response.json())
      .then((data) => setGroupData(data))
      .catch((error) => console.error("Error fetching group data:", error));
  }, []);

  useEffect(() => {
    // Retrieve category filter from query parameter in URL
    const params = new URLSearchParams(location.search);
    const categoryParam = params.get('category');
    if (categoryParam) {
      setCategoryFilter(categoryParam);
    }
  }, [location.search]);

  const handleSeeMore = (luxlinkcat) => {
    // Redirect to the same page with the category filter as a query parameter
    window.location.href = `/LuxurylinkCategory?category=${luxlinkcat}`;
  };

  const filteredBrands = data.filter((brand) => {
    const categoryMatch = categoryFilter === '' || brand.category === categoryFilter;
    const titleMatch = titleFilter === '' || brand.title.toLowerCase().includes(titleFilter.toLowerCase());

    return categoryMatch && titleMatch;
  });
  return (
    <Fragment>
      <div className="lux_category py-md-5 mt-md-4">
        {filteredBrands.map((item, index) => (
          <div className={`row w-100 mt-md-5 mt-3 ms-0 ${index % 2 === 0 ? '' : 'flex-row-reverse'}`} key={item.id}>
            {/* <div className="row w-100 mt-md-0 mt-0 ms-0"> */}
            <div className="col-md-6 px-0">
              <Carousel1
                image1={`https://testadmin1.phoenixmallofasia.com${item.photo1_path}`}
                image2={`https://testadmin1.phoenixmallofasia.com${item.photo2_path}`}
                image3={`https://testadmin1.phoenixmallofasia.com${item.photo3_path}`}
                image4={`https://testadmin1.phoenixmallofasia.com${item.photo4_path}`}
                image5={`https://testadmin1.phoenixmallofasia.com${item.photo5_path}`}
              />

              <div className="category-text">
                <h3 style={{ color: "black" }}>
                  {" "}
                  {item.title}<br /> <span>{item.title2}</span>{" "}
                </h3>
              </div>
            </div>
            <div className="col-md-6 px-0 px-md-3">
              <Container>
                <div className="row w-100 ms-0">
                  {groupData
                    .filter((group) => group.id === item.id)
                    .splice(0, 4)
                    .map((group, index) => (
                      <div className="col-md-6 col-6 px-md-3 px-1">
                        <a href={`/luxury/${encodeURIComponent(item.title1.split(',')[index])}`}>
                          <Card3lux
                            image={`https://testadmin1.phoenixmallofasia.com${group.photo1_path}`}
                            location="BANGALORE"
                            floor={group.floor}
                          />
                        </a>
                      </div>

                    ))}
                </div>
              </Container>
              <button onClick={() => handleSeeMore(item.luxlinkcat)}>SEE MORE</button>
              {/* <a href="/luxury-category">
                {" "}

                <button> SEE MORE </button>{" "}
              </a> */}
              {/* <button onClick={() => handleSeeMore(item.luxlinkcat)}>SEE MORE</button> */}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
}

export default LuxCategory;
