import { useState, useEffect } from "react";
import "./ServiceTab.css";
import ServiceCurb from "./ServiceCurb";
import ServiceDelivery from "./ServiceDelivery";
import ServiceShopper from "./ServiceShopper";

const content = [
  [<ServiceCurb />],
  [],
  [],
];

export default function ServiceTab() {
  const [activeContentIndex, setActiveContentIndex] = useState(0);
  const [serviceoneData, setTwoData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/servicesectionone")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <div className="service_tab">
      <div className="" id="tabs">
        <menu>
          <div className="row w-100 ms-0">
            <div className="col-md-15 col-15 px-0">
              <button
                className={activeContentIndex === 0 ? "active" : ""}
                onClick={() => setActiveContentIndex(0)}
              >
                {serviceoneData && serviceoneData.length > 0 && (

                  <h2> {serviceoneData[0].title}</h2>
                )}
              </button>
            </div>
            <div className="col-md-4 col-12 px-0">
            </div>
          </div>
        </menu>
        <div id="tab-content">
          <ul>
            {content[activeContentIndex].map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
