import React, { Fragment, useState, useEffect } from "react";
import "./BlogHead.css";
import LaunchBanner from "../../assets/Home/panache1.jpg";
import { BsCalendarDate, BsFillPersonFill } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { useParams } from 'react-router-dom'; // Import useParams
import axios from 'axios';

const BlogHead = (props) => {
  const { id } = useParams(); // Use useParams to access the 'id' parameter
  const [brand, setBrand] = useState(null);


  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/blog/${id}`);
        setBrand(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setBrand(null);
      }
    }

    fetchUserData();
  }, [id]);
  return (
    <Fragment>
      <div className="blog_head main-pad py-md-5">
        {brand && (
          <>
            <div>
              <img src={`https://testadmin1.phoenixmallofasia.com/${brand.photo2_path}`} />
            </div>
            <div className="d-flex">
              <label>
                <BsFillPersonFill /> {brand.author}
                {/* Jenny kiaa{" "} */}
              </label>
              <span> | </span>{" "}
              <label>
                <BsCalendarDate />{brand.date}
                {/* 02 december 2022{" "} */}
              </label>{" "}
              <span> | </span>{" "}
              <label>
                {" "}
                <BiTimeFive /> {brand.timing2}
                {/* 3 min. to read */}
              </label>
            </div>
            <p>{brand.blogsdesc1}
              {/* Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
              ab illo inventore veritatis et quasi architecto beatae vitae dicta
              sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
              qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
              dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
              quia non numquam eius modi tempora incidunt ut labore et dolore magnam
              aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
              exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex
              ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
              ea voluptate velit esse quam nihil molestiae consequatur, vel illum. */}
            </p>
            <div>
              <img src={`https://testadmin1.phoenixmallofasia.com${brand.gallery1_path}`} />
            </div>
            <p>{brand.blogsdesc2}
              {/* Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
              ab illo inventore veritatis et quasi architecto beatae vitae dicta
              sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
              qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
              dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
              quia non numquam eius modi tempora incidunt ut labore et dolore magnam
              aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
              exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex
              ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
              ea voluptate velit esse quam nihil molestiae consequatur, vel illum. */}
            </p>
            <div>
              <img src={`https://testadmin1.phoenixmallofasia.com${brand.gallery2_path}`} />
            </div>
            <p>{brand.blogsdesc3}
              {/* Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
              ab illo inventore veritatis et quasi architecto beatae vitae dicta
              sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
              qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
              dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
              quia non numquam eius modi tempora incidunt ut labore et dolore magnam
              aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
              exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex
              ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
              ea voluptate velit esse quam nihil molestiae consequatur, vel illum. */}
            </p>
          </>

        )}
      </div>
    </Fragment>
  );
};

export default BlogHead;
