import React, { Fragment, useState, useEffect } from "react";
import "./Carousel1.css";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";

const Carousel1new = () => {
  const [carouselItems, setCarouselItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://testadmin1.phoenixmallofasia.com/pages/api/newarrival");
      // Filter carousel items where sections is equal to "sectiontwo"
      const filteredItems = response.data.filter(item => item.sections === "sectiontwo");
      setCarouselItems(filteredItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="carousel1 pt-md-5 pb-md-0 pb-4">
      <Fragment>
        <div className="main-pad pe-md-0">
          <div className="d-flex pt-md-4">
            <h2> {carouselItems.length > 0 ? carouselItems[0].maintitle : ""} </h2>
            <hr />
          </div>
        </div>
        <Carousel className="mt-md-5 mt-3">
          {carouselItems.map((item, index) => (
            <Carousel.Item key={index}>
              <img src={`https://testadmin1.phoenixmallofasia.com${item.gallery1_path}`} alt={item.title} />
              <a href={item.ctalink}>
                <h3> {item.title}</h3>
                {/* <p>{item.desctext} </p> */}
              </a>
            </Carousel.Item>
          ))}
        </Carousel>
      </Fragment>
    </div>
  );
};

export default Carousel1new;
