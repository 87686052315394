import React, { Fragment, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./BlogHead.css";
import Card13 from "../Common/Card/Card13";
import { useParams } from 'react-router-dom';
import axios from 'axios';

const BlogMore = (props) => {
  const { id } = useParams();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function fetchBlogs() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/blog/${id}`);
        setBlogs(response.data.blogshomes.split(',').map(blogTitle => ({ title: blogTitle.trim() })));
      } catch (error) {
        console.error('Error fetching blogs: ' + error.message);
        setBlogs([]);
      }
    }

    fetchBlogs();
  }, [id]);

  useEffect(() => {
    async function fetchBlogData(blogTitle) {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/blog/${blogTitle}`);
        // Assuming the response data structure is similar to your initial API response
        // Modify this part according to your actual API response structure
        setBlogs(prevBlogs => prevBlogs.map(blog => {
          if (blog.title === blogTitle) {
            return response.data;
          }
          return blog;
        }));
      } catch (error) {
        console.error(`Error fetching data for blog "${blogTitle}": ` + error.message);
      }
    }

    blogs.forEach(blog => {
      if (blog.title) {
        fetchBlogData(blog.title);
      }
    });
  }, [blogs]);

  return (
    <Fragment>
      <div className="blog_more pb-md-5 pt-md-0 pt-4">
        <div className="main-pad pe-md-0 pe-0">
          <div className="d-flex pt-md-4">
            <h2> MORE FROM MALL OF ASIA </h2>
            <hr />
          </div>
        </div>
        <Container>

          <div className="row my-md-5 my-4">
            {blogs.slice(0, 2).map((blog, index) => (
              <div className="col-md-6" key={index}>
                <a href={`/blog/${blog.title}`}>
                  <Card13
                    image={`https://testadmin1.phoenixmallofasia.com/${blog.photo2_path}`}
                    title={blog.title}
                    para={blog.description}
                    author={blog.author}
                    date={blog.date}
                    time={blog.timing2}
                  />
                </a>
              </div>
            ))}
          </div>

        </Container>
      </div>
    </Fragment>
  );
};

export default BlogMore;
