import React, { Fragment, useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios"; // Import axios for making HTTP requests

import "./Grandeur.css";

const Grandeur = (props) => {
  const [zones, setZones] = useState([]);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    axios.get("https://testadmin1.phoenixmallofasia.com/pages/api/homesectionthrees")
      .then(response => {
        setZones(response.data);
      })
      .catch(error => {
        console.error('Error fetching zones:', error);
      });
  }, []);

  const [homethreeData, setEightData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/homesectionthree")
      .then((response) => response.json())
      .then((data) => setEightData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);


  return (
    <Fragment>
      <div className="grandeur pt-xl-4 pb-md-4 pt-4 pb-2">
        <div className="main-pad">
          <div className="d-flex">
            {/* <h2 className="py-md-4 pb-3 text-center w-100">WORLD OF GRANDEUR</h2> */}
            {
              homethreeData && homethreeData.length > 0 && (
                <>
                  <h2 className="py-md-4 pb-3 text-center w-100">{homethreeData[0].title2} </h2>
                </>
              )
            }
          </div>
          <Carousel>
            {zones.map(zone => (
              <Carousel.Item key={zone.id}>
                <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                  <div className="col-md-6 px-md-0">
                    <img src={`https://testadmin1.phoenixmallofasia.com${zone.gallery1_path}`} className="w-100" alt="Gallery 1" />
                  </div>
                  <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                    <div className="grandeur-text ps-md-5">
                      <div>
                        <h3> {zone.newzone} </h3>
                        <p className="mt-md-4 mt-2">
                          {zone.zonedesc}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                    <img src={`https://testadmin1.phoenixmallofasia.com${zone.gallery2_path}`} className="w-100" />
                  </div>
                  <div className="col-md-6 px-md-3 mt-md-3 d-none d-sm-block">
                    <img src={`https://testadmin1.phoenixmallofasia.com${zone.gallery3_path}`} className="w-100" />
                  </div>
                  {/* Render other gallery images and descriptions dynamically */}
                  {/* Add your dynamic rendering logic here */}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </Fragment>
  );
};

export default Grandeur;
