import React, { useState, useEffect } from "react";
import "./LuxCategory.css";
import Card3 from "../Common/Card/Card3";
import axios from 'axios';
import { useParams } from 'react-router-dom';

// Function to shuffle an array randomly
function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
}

const LuxuryCategorymore = () => {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const [luxury, setLuxury] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch('https://testadmin1.phoenixmallofasia.com/api/luxury');
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchUserData() {
            try {
                const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/luxury/${id}`);
                setLuxury(response.data);
            } catch (error) {
                console.error('Error fetching user data: ' + error.message);
                setLuxury(null);
            }
        }
        fetchUserData();
    }, [id]);

    // Filter the data to show only items with the same category as the selected luxury
    const categoryToDisplay = luxury ? luxury.category : '';
    const filteredLuxury = data.filter((luxury) => luxury.category === categoryToDisplay && luxury.title !== id);
    // console.log("filteredLuxury", filteredLuxury)

    // Shuffle the filtered data randomly 
    const shuffledLuxury = shuffleArray(filteredLuxury);

    return (
        <div className="brand_view main-pad">
            <div className="row px-md-0 px-2 brands_category">
                <div className="row px-md-0 px-2 ms-0">
                    {shuffledLuxury.slice(0, 4).map((luxury) => (
                        <div className="col-md-3 col-6 px-md-3 px-1" key={luxury.id}>
                            <a href={`/luxury/${luxury.title}`}>
                                <Card3
                                    category={luxury.category}
                                    image={`https://testadmin1.phoenixmallofasia.com${luxury.photo1_path}`}
                                    location={luxury.contact}
                                    floor={luxury.floor}
                                />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LuxuryCategorymore;
