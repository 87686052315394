import React, { Fragment, useState, useEffect } from "react";
import "./MediaNews.css";
import Container from "react-bootstrap/esm/Container";
import Carousel from "react-bootstrap/Carousel";
import { BsCalendarDate } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import axios from "axios";

const MediaNews = ({ id, title, title1, image, para, date, time }) => {
  const [zones, setZones] = useState([]);

  useEffect(() => {
    axios.get("https://testadmin1.phoenixmallofasia.com/events/api/newstable")
      .then(response => {
        // Filter the data to include only news with status "Press"
        const pressNews = response.data.filter(zone => zone.newsstatus === "News");
        setZones(pressNews);
      })
      .catch(error => {
        console.error('Error fetching zones:', error);
      });
  }, []);
  return (
    <div className="media_news py-md-5 py-4">
      <Fragment>
        <div className="main-pad pe-md-0">
          <div className="d-flex pt-md-4">
            {/* <h2> MEDIA AND NEWS </h2> */}
            <h2> {zones.length > 0 ? zones[0].toptitle : ""} </h2>
            <hr />
          </div>
        </div>
        <Container>
          <Carousel className="mt-md-5 mt-3">
            {zones.map(zone => (
              <Carousel.Item key={zone.id}>
                <a href={`/news/${zone.title}`}>
                  <div>
                    <img src={`https://testadmin1.phoenixmallofasia.com${zone.photo1_path}`} />
                  </div>
                  <div className="text">
                    <h3> {zone.title}</h3>
                    <div className="d-flex">
                      <label>
                        <BsCalendarDate />
                        {zone.date}{" "}
                      </label>{" "}
                      <span> | </span>{" "}
                      <label>
                        {" "}
                        <BiTimeFive /> {zone.timing}
                      </label>
                    </div>
                    <p>{zone.description} </p>
                  </div>
                </a>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </Fragment>
    </div>
  );
};

export default MediaNews;
