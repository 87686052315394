import React, { Fragment, useEffect, useState } from "react";
import "./BannerVideo.css";
import axios from "axios"; // Import axios for making HTTP requests

const BannerVideo = () => {
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get("https://testadmin1.phoenixmallofasia.com/pages/api/homesectionone");
        setBannerData(response.data[0]); // Assuming you only want the first item from the response array
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []); // Empty dependency array to ensure the effect runs only once

  if (!bannerData) {
    return <div>Loading...</div>; // Display loading indicator while fetching data
  }

  const { id, title, description, youtubeurl, photo1_path } = bannerData;

  return (
    <div className="banner_video">
      <Fragment>
        <video className="w-100" loop autoPlay muted>
          <source src={`https://testadmin1.phoenixmallofasia.com${photo1_path}`} type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="main-pad">
          <div className="banner-text">
            <div className="row">
              <div className="col-md-8">
                <h1>{title}</h1>
                <p className="mt-md-4 mt-2">{description}</p>
              </div>
              {/* <div className="col-md-4">
                <div class="social-media">
                  <Link href="">
                    {" "}
                    <BiLogoFacebook />{" "}
                  </Link>
                  <Link href="">
                    {" "}
                    <BiLogoTwitter />{" "}
                  </Link>
                  <Link href="">
                    {" "}
                    <BiLogoInstagram />
                  </Link>
                  <Link href="">
                    {" "}
                    <BiLogoYoutube />
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default BannerVideo;
