
import React, { Component } from "react";
import Coverflow from "react-coverflow";
import { Cont, NavigationContainer, Img } from "./elements";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import axios from "axios";

import "./Inox.css";
import Button1 from "../Common/Button/Button1";
import Button2 from "../Common/Button/Button2";

class Inox extends Component {
  state = {
    active: 0,
    movies: [],
    sectionFiveData: {},
  };

  componentDidMount() {
    axios
      .get("https://testadmin1.phoenixmallofasia.com/api/moviescategory")
      .then((response) => {
        this.setState({ movies: response.data }, () => {
          // Set the initial active index to the middle of the movies array
          const { movies } = this.state;
          const middleIndex = Math.floor(movies.length / 2);
          this.setState({ active: middleIndex });
        });
      })
      .catch((error) => {
        console.error("Error fetching movies:", error);
      });

    // Fetch data for section five
    axios
      .get("https://testadmin1.phoenixmallofasia.com/pages/api/homesectionfive")
      .then((response) => {
        this.setState({ sectionFiveData: response.data[0] });
      })
      .catch((error) => {
        console.error("Error fetching section five data:", error);
      });
  }

  onNext = () => {
    const { active, movies } = this.state;
    if (active < movies.length - 1) {
      this.setState({
        active: active + 1,
      });
    }
  };

  onPrev = () => {
    const { active } = this.state;
    if (active > 0) {
      this.setState({
        active: active - 1,
      });
    }
  };

  render() {
    const { active, movies, sectionFiveData } = this.state;

    return (
      <div className="inox d-none d-sm-block pt-md-4 pb-md-0">
        <div className="d-flex main-pad pe-0">
          {sectionFiveData && sectionFiveData.titlefive && <h2>{sectionFiveData.titlefive}</h2>}
          <hr />
        </div>
        <div className="container w-100">
          <div className="row align-items-center w-100 justify-content-center">
            <Cont className="w-100 react-coverflow">
              <Coverflow
                width={500}
                height={800}
                displayQuantityOfSide={2}
                enableHeading={false}
                active={active}
                currentFigureScale={1.9}
                otherFigureScale={1.4}
              >
                {movies.map((movie, index) => (
                  <div key={index}>
                    <Img
                      src={`https://testadmin1.phoenixmallofasia.com${movie.photo1_path}`}
                      alt="card"
                      data-action={movie.playlink} key={index}// Change to use playlink
                    />
                    {/* <div className="book_trailer mt-md-5">
                      <div>
                        <a href={movie.ticketlink || "#"}>
                          <Button2 title="BOOK NOW" />
                        </a>
                      </div>

                      <div className="ms-md-3">
                        <a href={movie.playlink || "#"}>
                          <Button2 title="PLAY TRAILER" />
                        </a>
                      </div>

                    </div> */}
                  </div>
                ))}
              </Coverflow>

              <NavigationContainer>
                <div className="nav-btn" onClick={this.onPrev}>
                  <BsArrowLeft />
                </div>

                <div className="nav-btn" onClick={this.onNext}>
                  <BsArrowRight />
                </div>
              </NavigationContainer>

            </Cont>
          </div>
          {sectionFiveData && sectionFiveData.ctalink5 && sectionFiveData.ctatext5 && (
            <div className="check-btn">
              <Link to={sectionFiveData.ctalink5}>
                <Button1 title={sectionFiveData.ctatext5} />
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Inox;

