import React, { Fragment, useState, useEffect } from "react";
import "./Carousel1.css";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";

const Carousel4new = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://testadmin1.phoenixmallofasia.com/pages/api/newarrival");
      // Filter items where sections is equal to "sectionfour"
      const filteredItems = response.data.filter(item => item.sections === "sectionfour");
      setItems(filteredItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="carousel1 pt-md-5 pb-md-0 pb-4">
      <Fragment>
        <div className="main-pad pe-md-0">
          <div className="d-flex">
            <h2> {items.length > 0 ? items[0].title : ""} </h2>
            <hr />
          </div>
        </div>
        <Carousel className="mt-md-5 mt-3">
          {items.map((item, index) => (
            <Carousel.Item key={index}>
              <img src={`https://testadmin1.phoenixmallofasia.com${item.gallery1_path}`} alt={item.title} />
              <h3> {item.title}</h3>
              {/* <p>{item.desctext} </p> */}
            </Carousel.Item>
          ))}
        </Carousel>
      </Fragment>
    </div>
  );
};

export default Carousel4new;
