import React, { Fragment } from "react";
import "./Luxury.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import Form1 from "../components/Common/Form1";
import LuxuryBanner from "../assets/Luxury/banner.jpg";
import Glory from "../assets/Luxury/glory.jpg";
import LuxCategory from "../components/Luxury/LuxCategory";
import Carousel1 from "../components/Common/Carousel/Carousel1";
import LuxEvents from "../components/Luxury/LuxEvents";

const Luxury = (props) => {
  return (
    <Fragment>
      <div className="luxury">
        <Header />
        <Sidebar />
        <Banner
          title="Coming Soon To MOA"
          para="Explore the newest, most exclusive designs at LV, synonymous with quality and class"
          image={LuxuryBanner}
        />
        <LuxCategory />
        <div className="jewellery_watch">
          <img src={Glory} />
          <div className="text">
            <h2>
              SHINING <br /> <span>Glory</span>
            </h2>
            <a href="/luxury-category">
              <button> EXPLORE MORE </button>
            </a>
          </div>
        </div>
        <Carousel1
          image1="img/Luxury/culinary1.jpg"
          image2="img/Luxury/culinary2.jpg"
          image3="img/Luxury/culinary3.jpg"
          image4="img/Luxury/culinary4.jpg"
          image5="img/Luxury/culinary5.jpg"
        />
        <div className="culinary_adv">
          <div>
            <h4> Coming Soon </h4>
            <h4> ENRICHING YOUR </h4>
            <h3> Culinary</h3>
            <p> ADVENTURE </p>
            <a href="/luxury-category">
              {" "}
              <button type="button"> EXPLORE MORE </button>{" "}
            </a>
          </div>
        </div>
        <LuxEvents />
        <Form1 tag="Luxurynew"
          title="Luxury is a part of our identity."
          para="If you have any questions, ask us here!"
        />
      </div>
    </Fragment>
  );
};

export default Luxury;
