import React, { Fragment, useState, useEffect } from "react";
import "../../pages/Leisure.css";
import MovieCard from "../Common/Card/Card7";
import Inox from "../../components/Home/Inox";
import InoxMob from "../../components/Home/InoxMob";
import Carousel3 from "../Common/Carousel/Carousel3";

const LeisureInox = (props) => {
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    fetch("https://testadmin1.phoenixmallofasia.com/api/moviespage")
      .then((response) => response.json())
      .then((data) => setMovies(data))
      .catch((error) => console.error("Error fetching movies:", error));
  }, []);
  return (
    <Fragment>
      <div className="leisure_inox my-md-5 mt-4">
        <div className="main-pad">
          <div className="d-flex py-md-3">
            <h2> Coming Soon </h2>
            {/* <hr /> */}
          </div>
          <div className="row w-100 ms-0">
            {movies
              // .filter((movie) => movie.statusmovie === "Running")
              .map((movie) => (
                <div key={movie.id} className="col-md-3 px-md-3 px-0">
                  <MovieCard
                    playurl={movie.playlink}
                    title={movie.title}
                    para={movie.language ? `Language: ${movie.language}` : ""}
                    image={`https://testadmin1.phoenixmallofasia.com${movie.photo1_path}`}
                    ticketlink={movie.ticketlink}
                  />
                </div>
              ))}
            {/* {movies.map((movie) => (
              <div key={movie.id} className="col-md-3 px-md-3 px-0">
                <MovieCard
                  playurl={movie.playlink}
                  title={movie.title}
                  para={movie.language ? `Language: ${movie.language}` : ""}
                  image={`https://testadmin1.phoenixmallofasia.com/${movie.photo1_path}`}
                />
              </div>
            ))} */}
            {/* <div className="col-md-3 px-md-3 px-0">
              <MovieCard playurl="" title="Avengers End Game" para="English | Telugu | Kannada | Tamil | Malyalam" image="img/Leisure/poster1.jpg" />

            </div>
            <div className="col-md-3 px-md-3 px-0">
              <MovieCard playurl="" title="D.I Jeo Snake Eyes" para="English" image="img/Leisure/poster2.jpg" />
            </div>
            <div className="col-md-3 px-md-3 px-0">
              <MovieCard playurl="" title="The Gray Man" para="English | Telugu | Kannada | Tamil | Malyalam" image="img/Leisure/poster3.jpg" />
            </div>
            <div className="col-md-3 px-md-3 px-0">
              <MovieCard playurl="" title="Avatar" para="English | Telugu " image="img/Leisure/poster4.jpg" />
            </div> */}
          </div>
        </div>

        <Inox />
        <InoxMob />
        <div className="leisure_inox my-md-5 mt-4">
          <Carousel3
            title="UPCOMING MOVIES"
            subtitle="Coming to theatres on 25th March 2023"
            button="PRE-BOOK"
            image="img/Leisure/upcoming.png"
            items={movies.filter((movie) => movie.statusmovie === "Upcoming").map((movie) => ({
              id: movie.id,
              title: movie.title,
              subtitle: `Status: ${movie.statusmovie}, Language: ${movie.language}`,
              image: `https://testadmin1.phoenixmallofasia.com${movie.photo1_path}`,
              // button: movie.ticketlink ? "PRE-BOOK" : "",
              button: movie.ticketlink ? "PRE-BOOK" : null,
              ticketlink: movie.ticketlink
            }))}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default LeisureInox;
