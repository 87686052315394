import React, { Fragment, useState, useEffect } from "react";
import "./Cooking.css";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import Button2 from "../Common/Button/Button2";

const Cookingnew = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://testadmin1.phoenixmallofasia.com/pages/api/newarrival");
      const filteredItems = response.data.filter(item => item.sections === "sectionfive");
      setItems(filteredItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="cooking pt-md-5 mt-md-4 pt-4">
      <Fragment>
        <div className="main-pad pe-0">
          <div className="d-flex mt-md-3">
            <h2> {items.length > 0 ? items[0].title : ""} </h2>
            <hr />
          </div>
        </div>
        <Carousel className="mt-md-5 mt-4">
          {items.reduce((accumulator, currentValue, index) => {
            if (index % 2 === 0) {
              accumulator.push(
                <Carousel.Item key={index}>
                  <div className="row">
                    <div className="col-md-6 px-0">
                      <div className="">
                        <img src={`https://testadmin1.phoenixmallofasia.com${currentValue.gallery1_path}`} alt={`Cooking ${index + 1}`} />
                        <a href={`${currentValue.title}`}>
                          <div className="text">
                            <h3>{currentValue.title}</h3>
                          </div>
                        </a>
                        <a href={`${currentValue.ctalink}`}>
                          <Button2 title={`${currentValue.ctatext}`} />
                        </a>
                      </div>
                    </div>
                    {items[index + 1] && (
                      <div className="col-md-6 px-0">
                        <div className="">
                          <img src={`https://testadmin1.phoenixmallofasia.com${items[index + 1].gallery1_path}`} alt={`Cooking ${index + 2}`} />
                          <a href={`${items[index + 1].ctalink}`}>
                            <div className="text">
                              <h3>{items[index + 1].title}</h3>
                            </div>
                          </a>
                          <a href={`${items[index + 1].ctalink}`}>
                            <Button2 title={`${items[index + 1].ctatext}`} />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </Carousel.Item>
              );
            }
            return accumulator;
          }, [])}
        </Carousel>
      </Fragment>
    </div>
  );
};

export default Cookingnew;
