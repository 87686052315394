import React, { Fragment, useEffect, useState } from "react";
import "./EventMain.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import EventCard from "../components/Events/EventCard";
import PastEvents from "../components/Events/PastEvent";
import Form1 from "../components/Common/Form1";

const EventMain = (props) => {
  const [eventoneData, setTwoData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/events/api/eventsectionone")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <Fragment>
      <div className="event_main">
        <Header />
        <Sidebar />
        {/* <Banner
          title="ALL ABOUT MUSIC - ITS ALL HERE"
          para="Turn on the feeling with all music event "
          image="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/05/7.png"
        /> */}
        {eventoneData && eventoneData.length > 0 && (
          <>
            <Banner title={eventoneData[0].title} para={eventoneData[0].description} image={`https://testadmin1.phoenixmallofasia.com${eventoneData[0].photo1_path}`} />

          </>
        )}
        <EventCard />
        {/* <Nhance /> */}
        <PastEvents />
        <Form1 tag="EventMain" title="Do you have any queries about the events?" para="Write to us and we’ll get back to you!" />
      </div>
    </Fragment>
  );
};

export default EventMain;
