import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Offers.css";
import { Link } from "react-router-dom";
import Button1 from "../Common/Button/Button1";
import Card1 from "../Common/Card/Card1";
import axios from "axios"; // Import axios

const Offers = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(
          "https://testadmin1.phoenixmallofasia.com/events/api/pastoffershome"
        );
        setOffers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching offers:", error);
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);


  const [homesixData, setSixData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/homesectionsix")
      .then((response) => response.json())
      .then((data) => setSixData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);



  return (
    <div className="offers pb-md-2 pt-md-0 pb-4">
      <div className="d-flex main-pad pe-0">
        {/* <h2> OFFERS </h2> */}
        {
          homesixData && homesixData.length > 0 && (
            <>
              <h2> {homesixData[0].title6} </h2>
            </>
          )
        }
        <hr />
      </div>
      <div className="main-pad">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="container-fluid pt-md-0 px-0">
            <OwlCarousel
              items={3}
              className="owl-theme mt-md-5 pt-md-3 mt-3"
              loop
              nav
              autoplay
              margin={25}
              responsive={{
                0: { items: 1 },
                450: { items: 1 },
                600: { items: 3 },
                1000: { items: 3 },
              }}
            >
              {offers.map((offer) => (
                <div key={offer.id}>
                  <Card1
                    image={`https://testadmin1.phoenixmallofasia.com${offer.photo2_path}`} // Assuming photo2_path contains image URLs
                    title={offer.title}
                    // subtitle={offer.packagestatus === "Offer"}
                    para={`${offer.date} to ${offer.expdate}`}
                  />
                </div>
              ))}
            </OwlCarousel>
          </div>
        )}
        <div className="view-btn">
          {/* <Link to="/offers-packages"> 
            <Button1 title="EXPLORE ALL OFFERS" />
          </Link> */}
          {
            homesixData && homesixData.length > 0 && (
              <>
                <Link to={homesixData[0].ctalink6}>
                  <Button1 title={homesixData[0].ctatext6} />
                </Link>

              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Offers;
