import React, { Fragment, useState, useEffect } from "react";
import "./Panache.css";
import Carousel from "react-bootstrap/Carousel";
import Button2 from "../Common/Button/Button2";
import axios from "axios";

const Panache = (props) => {
  const [panacheData, setPanacheData] = useState(null);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get("https://testadmin1.phoenixmallofasia.com/pages/api/homesectiontwo");
        setPanacheData(response.data[0]); // Assuming you only want the first item from the response array
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []); // Empty dependency array to ensure the effect runs only once

  if (!panacheData) {
    return <div>Loading...</div>; // Display loading indicator while fetching data
  }

  const { id, title, description, about, ctalink, ctatext, photo1_path, photo2_path, gallery1_path } = panacheData;
  return (
    <Fragment>
      <div className="panache w-100 pb-xl-5 pb-md-3 ">

        <div className="main-pad">
          <div className="d-flex pt-md-4">
            <h2 className="py-md-3"> {title} </h2>
          </div>
          <div className="row pt-md-5">
            <div className="col-md-6 pe-md-0">
              <Carousel>
                <Carousel.Item>
                  <img src={`https://testadmin1.phoenixmallofasia.com${photo1_path}`} />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={`https://testadmin1.phoenixmallofasia.com${photo2_path}`} />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={`https://testadmin1.phoenixmallofasia.com${gallery1_path}`} />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="col-md-6">
              <div className="panache-text">
                <h3 className="mt-md-2">{about}</h3>
                <p className="mt-md-3 mb-md-4 mt-2">
                  {description}
                </p>
                <a href={ctalink}>
                  <Button2 title={ctatext} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Panache;
