import React, { useEffect, useState } from "react";

import BannerVideo from "../../assets/Home/prashasti.mp4";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Events.css";
import Button1 from "../Common/Button/Button1";


function Events() {
  const [events, setEvents] = useState([]);


  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/events/api/pasteventhome")
      .then((response) => response.json())
      .then((data) => {
        setEvents(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const [firstEvent, setFirstEvent] = useState(null);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/events/api/pasteventhome")
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          // Set the first event data
          setFirstEvent(data[0]);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);


  const [homesevenData, setSevenData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/homesectionseven")
      .then((response) => response.json())
      .then((data) => setSevenData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Limit the number of events to be shown in tabs
  const eventsInTabs = events.slice(0, 5);
  const defaultActiveKey = events.length > 0 ? events[0].id : null;
  console.log(defaultActiveKey);
  return (
    <div className="events pt-md-4 pb-md-0 pb-4">
      <header className="main-pad pe-0">
        <div className="d-flex">
          {/* <h2> Events &nbsp; </h2> */}
          {
            homesevenData && homesevenData.length > 0 && (
              <>
                <h2> {homesevenData[0].title7} &nbsp; </h2>
              </>
            )
          }
          <hr />
        </div>
      </header>
      <div className="main-pad mt-md-0">
        {firstEvent && (
          <Tabs defaultActiveKey={firstEvent.id} id="uncontrolled-tab-example" className="mb-4">
            {eventsInTabs.map((event) => (
              <Tab key={event.id} eventKey={event.id} title={event.title}>
                <div className="row mt-md-4">
                  <div className="col-md-6">
                    <img src={`https://testadmin1.phoenixmallofasia.com${event.photo1_path}`} alt={`Event ${event.title}`} />
                  </div>
                  <div className="col-md-6">
                    <video className="w-100" loop autoPlay muted>
                      <source src={`${event.gallery4_path}`} type="video/mp4" />
                    </video>
                    {/* <div className="video-container">
                      <iframe
                        title="YouTube video player"
                        width="100%"
                        height="415"
                        src="https://www.youtube.com/embed/co4EBYZZhg4"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div> */}
                    <div className="text">
                      <h3>{event.abouttitle}</h3>
                      <p><span>Date: </span>{event.date}</p>
                      <p><span>Location: </span>{event.venue}</p>
                    </div>
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs>
        )}
      </div>
      <div className="view-btn mt-md-4">
        {/* <a href="\events">
          <Button1 title="EXPLORE" />
        </a> */}
        {homesevenData && homesevenData.length > 0 && (
          <>
            <a href={homesevenData[0].ctalink7}>
              <Button1 title={homesevenData[0].ctatext7} />
            </a>

          </>
        )}
      </div>
    </div>
  );
}

export default Events;
