import React, { Fragment } from "react";
import "./Dine.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import Foodthopia from "../assets/Dine/Food THopia 1.jpg";
import Help from "../components/Home/Help";
import More from "../components/Common/More";
import Form1 from "../components/Common/Form1";
import DinefineCategory from "../components/Dine/DinefineCategory";
import DineView from "../components/Dine/DineViewMore";

const Dine = (props) => {
  return (
    <Fragment>
      <div className="dine">
        <Header />
        <Sidebar />
        <Banner
          title="Satisfy your Exquisite Palette"
          para="The most diverse selection of dining options that appeal to the most sophisticated connoisseurs"
          image="img/Dine/Dinebanner2.jpg"
        />
        <div>
          <div className="py-md-5 pt-4">
            <DinefineCategory />
          </div>
        </div>
        <div className="foodthopia mb-md-4 mb-xl-0">
          <img src={Foodthopia} />
          <h2>FOODTHOPIA - THE FOOD COURT </h2>
        </div>
        <More
          title1="SERVICES"
          title2="BRANDS"
          image1="img/Brands/more1.jpg"
          image2="img/Brands/more2.jpg"
          url="/brands"
        />
        <Help />
        <Form1 tag="Dinefine"
          title="Are you having trouble deciding where to eat? "
          para="Ask us and we'll be happy to help you choose!"
        />
      </div>
    </Fragment>
  );
};

export default Dine;
