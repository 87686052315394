import React, { Fragment, useState, useEffect } from "react";
import Carousel1 from "../Common/Carousel/Carousel1";
import ParkCard from "../Common/Card/Card8";
import axios from 'axios';

const LeisurePark = (props) => {
  const [parkthreeData, setTwoData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/parksectionthree")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  const [news, setNews] = useState([]);

  useEffect(() => {
    axios.get("https://testadmin1.phoenixmallofasia.com/events/api/eventstable")
      .then(response => {
        // Filter the data to include only news with status "Press"
        const pressNews = response.data.filter(news => news.statusvenue === "FanPark");
        setNews(pressNews);
      })
      .catch(error => {
        console.error('Error fetching news:', error);
      });
  }, []);

  return (
    <Fragment>
      <div className="leisure_park pt-md-5 pb-md-4 pt-4" >
        <div className="main-pad">
          <div className="d-flex py-md-2">
            <h2> UPCOMING EVENTS </h2>
            {/* <hr /> */}
          </div>
          <div className="row mb-md-3">
            {news.map(news => (
              <div key={news.id} className="col-md-4">
                <ParkCard
                  title={news.title}
                  subtitle={news.artist}
                  para={news.description}
                  image={`https://testadmin1.phoenixmallofasia.com${news.photo1_path}`}
                />
              </div>
            ))}

            {/*<div className="col-md-4">
              <ParkCard
                title="BMW 5 Series"
                subtitle="Brand Launch"
                para="Date: 02nd Aug 2023"
                image="img/Leisure/fp2.jpg"
              />
            </div>
            <div className="col-md-4">
              <ParkCard
                title="Vir Das"
                subtitle="Standup Comedy"
                para="Date: 02nd Aug 2023"
                image="img/Leisure/fp3.jpg"
              />
  </div>*/}
          </div>
        </div>
        {parkthreeData && parkthreeData.length > 0 && (
          <Carousel1
            image1={`https://testadmin1.phoenixmallofasia.com${parkthreeData[0].newphoto1_path}`}
            image2={`https://testadmin1.phoenixmallofasia.com${parkthreeData[0].newphoto2_path}`}
            image3={`https://testadmin1.phoenixmallofasia.com${parkthreeData[0].newphoto3_path}`}
            image4={`https://testadmin1.phoenixmallofasia.com${parkthreeData[0].newphoto4_path}`}
            image5={`https://testadmin1.phoenixmallofasia.com${parkthreeData[0].newphoto5_path}`}
          />
        )}
        {/* <Carousel3 image="img/Leisure/upcoming.png" /> */}
      </div>
    </Fragment>
  );
};

export default LeisurePark;
