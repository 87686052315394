import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import Form1 from "../components/Common/Form1";
import Card1 from "../components/Common/Card/Card1";

const OfferPackages = (props) => {
  const [offers, setOffers] = useState([]);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://testadmin1.phoenixmallofasia.com/events/api/offerslist");
        const { data } = response;
        setOffers(data.filter(item => item.packagestatus === "Offer" && item.offersstatus === "Running"));
        setPackages(data.filter(item => item.packagestatus === "Package" && item.offersstatus === "Running"));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const [offersoneData, setOneData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/events/api/offerssectionone")
      .then((response) => response.json())
      .then((data) => setOneData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const [offerstwoData, setTwoData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/events/api/offerssectiontwo")
      .then((response) => response.json())
      .then((data) => setTwoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (


    <div className="offers_packages">
      <Fragment>
        <Header />
        <Sidebar />
        {/* <Banner image="img/Offers/banner.jpg" /> */}
        {offersoneData && offersoneData.length > 0 && (
          <>
            <Banner image={`https://testadmin1.phoenixmallofasia.com${offersoneData[0].photo1_path}`} />

          </>
        )}
        {/* Offers */}
        <div className="main-pad pt-md-5 pt-4">
          <div className="d-flex">
            {offerstwoData && offerstwoData.length > 0 && (
              <>
                <h2> {offerstwoData[0].title2} </h2>
              </>
            )}

            <hr />
          </div>
          <div className="row py-md-5">
            {offers.map((offer) => (
              <div key={offer.id} className="col-md-3 col-12">
                <Card1
                  image={`https://testadmin1.phoenixmallofasia.com${offer.photo2_path}`}
                  title={offer.title}
                  subtitle="Getting new offers to you"
                  para={`Expires on ${offer.expdate}`}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Packages */}
        <div className="main-pad pe-0">
          <div className="d-flex">
            {offerstwoData && offerstwoData.length > 0 && (
              <>
                <h2> {offerstwoData[0].title3} </h2>
              </>
            )}
            <hr />
          </div>
        </div>
        <div className="main-pad">
          <div className="row py-md-5 my-md-4 mt-4">
            {packages.map((pkg) => (
              <div key={pkg.id} className="col-md-3 col-12">
                <Card1
                  image={`https://testadmin1.phoenixmallofasia.com${pkg.photo2_path}`}
                  title={pkg.title}
                  subtitle={pkg.title}
                  para={`Expires on ${pkg.expdate}`}
                />
              </div>
            ))}
          </div>
        </div>

        <Form1
          tag="Offers/Packages"
          title="Have a question? Connect with us!"
          para="Write to us and we will reach out shortly."
        />
      </Fragment>
    </div>
  );
};

export default OfferPackages;
