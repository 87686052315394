import React, { Fragment, useEffect, useState } from "react";
import "./Blogs.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import BlogView from "../components/Blogs/BlogViewMore";
import BlogCategory from "../components/Blogs/BlogCategory";
import Form1 from "../components/Common/Form1";

const Blogs = (props) => {
  const [blogoneData, setOneData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/events/api/blogsectionone")
      .then((response) => response.json())
      .then((data) => setOneData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <Fragment>
      <div className="blogs">
        <Header />
        <Sidebar />
        {/* <Banner title="BLOGS" image="img/Blog/banner.png" /> */}
        {blogoneData && blogoneData.length > 0 && (
          <>
            <Banner title={blogoneData[0].title} image={`https://testadmin1.phoenixmallofasia.com${blogoneData[0].photo1_path}`} />

          </>
        )}
        <BlogCategory />
        {/* <BlogView /> */}
        <Form1
          tag="Blogs"
          title="Have a question? Connect with us!"
          para="Write to us and we will reach out shortly."
        />
      </div>
    </Fragment>
  );
};

export default Blogs;
