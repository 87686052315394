import React, { Fragment, useState, useEffect } from "react";
import "./Blogs.css";
import Banner from "../components/Common/Banner";
import LaunchBanner from "../assets/Home/panache1.jpg";
import Form1 from "../components/Common/Form1";
import BlogHead from "../components/Blogs/BlogHead";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import BlogMore from "../components/Blogs/BlogMore";
import { useParams } from 'react-router-dom'; // Import useParams
import axios from 'axios';

const BlogDetails = (props) => {
  const { id } = useParams(); // Use useParams to access the 'id' parameter
  const [blog, setBlog] = useState(null);


  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/blog/${id}`);
        setBlog(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setBlog(null);
      }
    }

    fetchUserData();
  }, [id]);
  return (
    <Fragment>
      <div className="blog_details">
        <Header />
        <Sidebar />
        {blog && (
          <>
            <Banner title={blog.title} image={`https://testadmin1.phoenixmallofasia.com/${blog.photo2_path}`} />
          </>

        )}
        <BlogHead />
        <BlogMore />
        <Form1
          tag="BlogDetails"
          title="Have a question? Connect with us!"
          para="Write to us and we will reach out shortly."
        />
      </div>
    </Fragment>
  );
};

export default BlogDetails;
