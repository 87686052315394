import React, { Fragment, useEffect, useState } from "react";
import "./Categories.css";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation from react-router-dom
import Button1 from "../Common/Button/Button1";
import { useParams } from 'react-router-dom';
import axios from 'axios';

function Categories() {
  const [brandData, setBrandData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [brand, setBrand] = useState(null);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [titleFilter, setTitleFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const location = useLocation(); // Use useLocation to access the current location

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://testadmin1.phoenixmallofasia.com/api/homebrandhome');
        const jsonData = await response.json();
        setData(jsonData);
        // Extract unique categories from the data
        const uniqueCategories = [...new Set(jsonData.map((brand) => brand.category))];
        setUniqueCategories(uniqueCategories);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://testadmin1.phoenixmallofasia.com/api/homebrandhome/${id}`);
        setBrand(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setBrand(null);
      }
    }
    fetchUserData();
  }, [id]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://testadmin1.phoenixmallofasia.com/api/homegroup")
      .then((response) => response.json())
      .then((data) => setGroupData(data))
      .catch((error) => console.error("Error fetching group data:", error));
  }, []);

  useEffect(() => {
    // Retrieve category filter from query parameter in URL
    const params = new URLSearchParams(location.search);
    const categoryParam = params.get('category');
    if (categoryParam) {
      setCategoryFilter(categoryParam);
    }
  }, [location.search]);

  const handleSeeMore = (homelinkcat) => {
    // Redirect to the same page with the category filter as a query parameter
    window.location.href = `/BrandslinkCategory?category=${homelinkcat}`;
  };

  const filteredBrands = data.filter((brand) => {
    const categoryMatch = categoryFilter === '' || brand.category === categoryFilter;
    const titleMatch = titleFilter === '' || brand.title.toLowerCase().includes(titleFilter.toLowerCase());

    return categoryMatch && titleMatch;
  });
  const [homefourData, setFourData] = useState(null);
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://testadmin1.phoenixmallofasia.com/pages/api/homesectionfour")
      .then((response) => response.json())
      .then((data) => setFourData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <Fragment>
      <div className="categories pt-md-0 pb-md-4 pb-4 mt-xl-4">
        <div className="d-flex pb-md-3 main-pad pe-0">
          {homefourData && homefourData.length > 0 && (
            <>
              <h2> {homefourData[0].title4} </h2>
            </>
          )}
          <hr />
        </div>
        <div className="main-pad">
          {filteredBrands.map((item, index) => (
            <div className={`row w-100 mt-md-5 mt-3 ms-0 ${index % 2 === 0 ? '' : 'flex-row-reverse'}`} key={item.id}>
              <div className="col-md-4 px-md-0 px-0">
                <a href={item.url}>
                  <img className="main_img" src={`https://testadmin1.phoenixmallofasia.com${item.photo1_path}`} alt={item.title2} />
                  <div className="category-text">
                    <h3>{item.title2}</h3>
                  </div>
                </a>
              </div>
              <div className="col-md-8 pe-md-0 mt-md-0 mt-4">
                <div className="row w-100 ms-0">
                  {groupData
                    .filter((group) => group.id === item.id)
                    .splice(0, 8)
                    .map((group, index) => (
                      <div className="col-md-3 col-6" key={index}>
                        <a href={`/${group.status}/${encodeURIComponent(item.title1.split(',')[index])}`}>
                          <div className="card">
                            <img src={`${group.photo1_path}`} alt={`Group ${index}`} style={{ width: "150px", height: "150px" }} />
                          </div>
                        </a>
                      </div>
                    ))}
                </div>
                <button onClick={() => handleSeeMore(item.homelinkcat)}>SEE MORE</button>
              </div>
            </div>
          ))}
          <div className="view-btn">
            {homefourData && homefourData.length > 0 && (
              <>
                <a href={homefourData[0].ctalink4}>
                  <Button1 title={homefourData[0].ctatext4} />
                </a>

              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Categories;
