import React, { Fragment } from "react";
import "./Carousel1.css";
import Carousel from "react-bootstrap/Carousel";

const Carousel1 = ({
  id,
  title,
  title1,
  title2,
  title3,
  title4,
  title5,
  image1,
  image2,
  image3,
  image4,
  image5,
  para1,
  para2,
  Link1,
  Link2,
  Link3,
  Link4,
  Link5,
}) => {
  const addBanner = () => {
    dispatchEvent({
      item: {
        id: id,
        title: title,
        title1: title1,
        title2: title2,
        title3: title3,
        title4: title4,
        title5: title5,
        image1: image1,
        image2: image2,
        image3: image3,
        image4: image4,
        image5: image5,
        para1: para1,
        para2: para2,
        Link1: Link1,
        Link2: Link2,
        Link3: Link3,
        Link4: Link4,
        Link5: Link5,
      },
    });
  };
  return (
    <div className="carousel1 pt-md-5 pb-md-0 pb-4">
      <Fragment>
        <div className="main-pad pe-md-0">
          <div className="d-flex pt-md-4">
            <h2> {title} </h2>
            <hr />
          </div>
        </div>
        <Carousel className="mt-md-5 mt-3">
          <Carousel.Item>
            <img src={image1} />
            <a href={Link1}>
              <h3> {title1}</h3>
              <p>{para1} </p>
            </a>
          </Carousel.Item>
          <Carousel.Item>
            <img src={image2} />
            <div className="">
              <a href={Link2}>
                <h3> {title2}</h3>
                <p>{para2} </p>
              </a>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img src={image3} />
            <a href={Link3}>
              <h3> {title3}</h3>
            </a>
          </Carousel.Item>
          <Carousel.Item>
            <img src={image4} />
            <a href={Link4}>
              <h3> {title4}</h3>
            </a>
          </Carousel.Item>
          <Carousel.Item>
            <img src={image5} />
            <a href={Link5}>
              <h3> {title5}</h3>
            </a>
          </Carousel.Item>
        </Carousel>
      </Fragment>
    </div>
  );
};

export default Carousel1;
